import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';

import 'semantic-ui-css/semantic.css';
import './index.css';
import I18n from './helpers/I18n';
import { getCurrentUser } from './actions/authentication';

import Root from './containers/Root';
console.log('Environment', process.env);
I18n.initialize(getCurrentUser());

ReactDOM.render(
  (
    <I18nextProvider i18n={I18n.i18n} >
      <Root />
    </I18nextProvider>
  ),
  document.getElementById('root'));
