import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { getPatientData } from '../services';
import Layout from '../components/Layout';
import Patient from '../components/patients_show/Patient';

class PatientShowContainer extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.getPatientData = this.getPatientData.bind(this);

    this.state = {
      patientId: props.match.params.id,
      data: null
    };
  }

  componentDidMount() {
    this.getPatientData();
  }

  componentWillReceiveProps() {
    this.getPatientData();
  }

  getPatientData(props) {
    getPatientData(this.state.patientId)
      .then((payload) => this.setState({ data: payload }));
  }

  render() {
    const currentPath =  this.props.location.pathname;
    return (
      <Layout location={{pathname: '/patients'}}>
        <Patient patient={this.state.data} path={currentPath} />
      </Layout>
    );
  }
}

export default withRouter(PatientShowContainer);
