import React, { Component } from 'react';
import moment from 'moment';
import uuid from 'uuid';
import { translate } from 'react-i18next';
import SessionFormErrorMessage from './SessionFormErrorMessage';
import UserDetails from './UserDetails';
import OrderDetails from './OrderDetails';
import GlassesRxDetails from './GlassesRxDetails';
import ContactsRxDetails from './ContactsRxDetails';
import { getCurrentUser } from '../../actions/authentication';
import { Item, Segment, Button, Container, Form, Grid, Header } from 'semantic-ui-react';
import { parseErrors, errorsArray } from '../../helpers/common.js'
import { findIndex, merge } from 'lodash';
import LanguageService from '../../services/LanguageService';

const DEFAULT_INSTORE_CHARGED_AMOUNT = 0;
const DEFAULT_LANGUAGE = 'en';
const UNREGULATED_PRODUCT = [{type: 'glasses'}]

export class CreateSessionFormComponent extends Component {
  state = {
    first_name: undefined,
    last_name: undefined,
    birthdate: undefined,
    email: getCurrentUser().ecp_location.email,
    region: getCurrentUser().ecp_location.region,
    regionRegulated: getCurrentUser().ecp_location.region_regulated,
    products: getCurrentUser().ecp_location.region_regulated ? undefined : UNREGULATED_PRODUCT,
    charged_amount: DEFAULT_INSTORE_CHARGED_AMOUNT,
    error: undefined,
    errorList: undefined,
    isSubmitting: false,
    userFormErrors: {},
    languages: [],
    // define default language here until main app defines out country/in person site preferred lang
    language: DEFAULT_LANGUAGE,
    glasses_rx: {
      type: 'glasses',
      verification: null,
      left_eye: {},
      right_eye: {}
    },
    showGlassesRxForm: false,
    validations: {
      verificationIsInvalid: null
    },
    glassesRxFormErrors: {
      left_eye: {},
      right_eye: {}
    },
    contacts_rx: {
      type: 'contacts',
      verification: 'verified',
      left_eye: {},
      right_eye: {}
    },
    contactModelDetails: {
      left_eye: {},
      right_eye: {}
    },
    contactsRxFormErrors: {
      left_eye: {},
      right_eye: {}
    },
    showContactsRxForm: false,
    contactsModels: [],
    wearing: undefined,
    knowPriors: undefined
  }

  constructor(props) {
    super(props);
    this.handleFullName = this.handleFullName.bind(this);
    this.validateFullName = this.validateFullName.bind(this);
    this.handleDateOfBirth = this.handleDateOfBirth.bind(this);
    this.validateDateOfBirth = this.validateDateOfBirth.bind(this);
    this.handleProducts = this.handleProducts.bind(this);
    this.handleLanguage = this.handleLanguage.bind(this);
    this.handleWearing = this.handleWearing.bind(this);
    this.handlePriorAwareness = this.handlePriorAwareness.bind(this);
    this.addRx = this.addRx.bind(this);
    this.removeRx = this.removeRx.bind(this);
    this.handleContactModelChange = this.handleContactModelChange.bind(this);
    this.handleRxValue = this.handleRxValue.bind(this);
    this.handleVerificationValue = this.handleVerificationValue.bind(this);
    this.handleCopyEyeValues = this.handleCopyEyeValues.bind(this);
    this.canBeSubmitted = this.canBeSubmitted.bind(this);
    this.handleErrors = this.handleErrors.bind(this);
    this.submit = this.submit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.validateFormAndAttemptToSubmit = this.validateFormAndAttemptToSubmit.bind(this);
    this.submitBtnDisabled = this.submitBtnDisabled.bind(this);
  }

  componentDidMount() {
    this.props.fetchContactsModels()
      .then((res) => this.setState({
        contactsModels: res.data
      }))

    LanguageService.fetchActiveLanguages()
      .then((res) => this.setState({
        languages: res.data.available_languages.map(lng => {
          return { key: lng.locale, value: lng.locale, text: lng.language }
        })
      }))
  }

  handleFullName(event) {
    const fullName = event.target.value.split(' ');
    let [first_name, ...last_name] = fullName;
    last_name = last_name.join(' ');

    this.setState({ first_name, last_name });
  }

  validateFullName(event) {
    const { first_name, last_name } = this.state;
    this.setState({ 
      userFormErrors: { ...this.state.userFormErrors, fullName: (!first_name || !last_name) }
    });
  }

  handleDateOfBirth(event) {
    this.setState({ birthdate: moment(event.target.value, 'YYYY-MM-DD').toISOString() });
  }

  validateDateOfBirth(event) {
    this.setState({ userFormErrors: { ...this.state.userFormErrors, dob: !moment(event.target.value).isValid() } });
  }

  handleProducts(products) {
    this.setState({ products });
  }

  handleWearing(wearing) {
    this.setState({ wearing });
    if (wearing === 'none') {
      this.handlePriorAwareness(null);
      this.removeRx('glasses_rx');
    }
  }

  handlePriorAwareness(knowPriors) {
    this.setState({ knowPriors });
    if (knowPriors === 'no') {
      this.removeRx('glasses_rx')
    }
  }

  handleLanguage(_, data) {
    this.setState({ language: data.value })
  }

  addRx(rx) {
    this.setState(
      rx === 'contacts_rx' ?
        { showContactsRxForm: true } :
        { showGlassesRxForm: true }
    );
  }

  removeRx(rx) {
    this.setState(
      rx === 'contacts_rx' ? (
          {
            showContactsRxForm: false,
            contacts_rx: {
              type: 'contacts',
              verification: 'verified',
              left_eye: {},
              right_eye: {},
            }
          }
        ) : (
          {
            showGlassesRxForm: false,
            glasses_rx: {
              type: 'glasses',
              verification: null,
              left_eye: {},
              right_eye: {}
            },
            validations: {
              verificationIsInvalid: false
            }
          }
        )
    );
  }

  async handleRxValue(whichRx, eye, rxField, rxValue) {
    if (rxField === 'product_key' && whichRx === 'contacts_rx') {
      await this.handleContactModelChange(eye, rxValue);
    }

    const rx = Object.assign({}, this.state[whichRx]);
    rx[eye][rxField] = rxValue;
    return new Promise((res) => {
      this.setState({ [whichRx]: rx }, res);
    });
  }

  async handleContactModelChange(eye, contactModelId) {
    try {
      const res = await this.props.fetchContactModelDetail(contactModelId);
      const eyeContactDetail = Object.assign({}, this.state.contactModelDetails[eye]);
      const { id, avail_sph, avail_cyl, avail_axis, avail_add, avail_bc, avail_diam } = res.data;

      Object.assign(
        eyeContactDetail,
        { avail_sph, avail_cyl, avail_axis, avail_add, avail_bc, avail_diam }
      );

      const rx = this.handleSingluarContactModelDetailValues(eye, id, eyeContactDetail);

      this.setState({
        contacts_rx: {
          ...this.state.contacts_rx,
          [eye]: rx
        },
        contactModelDetails: {
          ...this.state.contactModelDetails,
          [eye]: eyeContactDetail
        }
      });
    } catch (e) {
      console.error(e);
      alert('Contacts Model cannot be found!');
    }
  }

  handleSingluarContactModelDetailValues(eye, id, eyeContactDetail) {
    const rx = { product_key: id };
    const allKeys = {
      'sphere': 'avail_sph',
      'cylinder': 'avail_cyl',
      'axis': 'avail_axis',
      'add': 'avail_add',
      'base_curve': 'avail_bc',
      'diameter': 'avail_diam'
    };

    Object.entries(allKeys).forEach(([rxField, detailField]) => {
      if (eyeContactDetail[detailField] && eyeContactDetail[detailField].length === 1) {
        rx[rxField] = eyeContactDetail[detailField][0];
      };
    })

    return rx;
  }

  handleVerificationValue(value) {
    this.setState({
      glasses_rx: {...this.state.glasses_rx, verification: value },
      validations: {verificationIsInvalid: false}
    });
  }

  handleCopyEyeValues(whichRx, eye) {
    const eyeToReceiveCopyValues = eye === 'right_eye' ? 'left_eye' : 'right_eye';
    const rxCopyValues = Object.assign({}, this.state[whichRx][eye]);
    const newStateValues = {
      [whichRx]: {
        ...this.state[whichRx],
        [eyeToReceiveCopyValues]: rxCopyValues,
      },
    };

    if (whichRx === 'contacts_rx') {
      const eyeContactDetailCopyValues = Object.assign({}, this.state.contactModelDetails[eye]);

      Object.assign(newStateValues, {
        contactModelDetails: {
          ...this.state.contactModelDetails,
          [eyeToReceiveCopyValues]: eyeContactDetailCopyValues,
        }
      });
    };

    this.setState(newStateValues);
  }

  handleErrors(error, payload) {
    let allGlassesErrors = {
      left_eye: {},
      right_eye: {}
    };
    let allContactsErrors = {
      left_eye: {},
      right_eye: {}
    };
    let allUserErrors = {};

    const errorList = [];
    const glassesRX = findIndex(payload.prescriptions, rx => rx.type === 'glasses');
    const contactsRX = findIndex(payload.prescriptions, rx => rx.type === 'contacts');
    const errors = parseErrors(error.errors);

    if (errors.prescriptions && errors.prescriptions[glassesRX]) {
      merge(allGlassesErrors, errors.prescriptions[glassesRX]);
      errorList.push(errorsArray('glasses', allGlassesErrors));
    }

    if (errors.prescriptions && errors.prescriptions[contactsRX]) {
      merge(allContactsErrors, errors.prescriptions[contactsRX]);
      errorList.push(errorsArray('contacts', allContactsErrors));
    }

    if (errors.user && errors.user.email) {
      errorList.push(['User email is invalid.']);
      allUserErrors.email = ['is invalid'];
    }

    if (error.disqualifications) {
      errorList.push(['User is ineligible.']);
    }

    this.setState({
      isSubmitting: false,
      error: error,
      errorList: errorList,
      glassesRxFormErrors: allGlassesErrors,
      contactsRxFormErrors: allContactsErrors,
      userFormErrors: allUserErrors
    });
  }

  canBeSubmitted() {
    const { first_name, last_name, birthdate, email, region, products, charged_amount } = this.state;
    if(!!first_name && !!last_name && !!birthdate && !!email && !!region && !!products && (!!charged_amount || charged_amount >= 0)) {
      return (
        first_name.length > 0
        && last_name.length > 0
        && birthdate.length > 0
        && region.length > 0
        && products.length > 0
        && charged_amount >= 0
      );
    }

    return false;
  }

  validateForm () {
    const { glasses_rx, showGlassesRxForm } = this.state;
    const { verification } = glasses_rx;
    const verificationIsInvalid = showGlassesRxForm && !verification;
    this.setState({ validations: { verificationIsInvalid } });
    const formIsInvalid = verificationIsInvalid;
    return !formIsInvalid;
  }

  validateFormAndAttemptToSubmit () {
    if (this.state.isSubmitting) return;

    this.setState({
      error: undefined,
      errorList: undefined,
    });

    return this.validateForm() ?
      this.submit() :
      null;
  }

  async submit () {
    this.setState({isSubmitting: true})
    const { first_name,
      last_name,
      birthdate,
      email,
      language,
      region,
      products,
      charged_amount,
      glasses_rx,
      showGlassesRxForm,
      contacts_rx,
      showContactsRxForm
    } = this.state;

    const uniqueID = uuid.v4();
    const prescriptions = []
    if(showGlassesRxForm) { prescriptions.push(glasses_rx); }
    if(showContactsRxForm) { prescriptions.push(contacts_rx); }

    const payload = {
      prescriptions,
      user: { first_name, last_name, birthdate, email, region, language },
      order: { products, charged_amount, charged_currency: 'USD' },
      user_key: uniqueID,
      session_key: uniqueID
    };

    try {
      await this.props.createSession(payload);
    } catch(e) {
      console.error(e);

      this.handleErrors(e, payload);

      this.setState({
        isSubmitting: false,
        error: e
      });

      window.scrollTo(0, 0);
    }
  }

  submitBtnDisabled () {
    return !this.canBeSubmitted() || this.state.isSubmitting;
  }

  render() {
    const { t } = this.props;
    const { region_refraction_active, region_regulated } = getCurrentUser().ecp_location;

    return (
      <Container>
        <Header as='h1'>
          {t('header')}
        </Header>
        <SessionFormErrorMessage
          error={this.state.error}
          errorList={this.state.errorList} />
        <Form id='create_session_form' onSubmit={()=>this.validateFormAndAttemptToSubmit()}>
          <Segment>
            <Grid stackable columns={2}>
              <Grid.Column>
                <Header as='h2'>
                  {t('userDetailsTitle')}
                </Header>
              </Grid.Column>
              <Grid.Column>
                <UserDetails
                  onNameChange={this.handleFullName}
                  onNameBlur={this.validateFullName}
                  onDOBChange={this.handleDateOfBirth}
                  onDOBBlur={this.validateDateOfBirth}
                  onRegionChange={this.handleRegion}
                  onEmailChange={this.handleEmail}
                  handleLanguage={this.handleLanguage}
                  languages={this.state.languages}
                  userFormErrors={this.state.userFormErrors} />
              </Grid.Column>
            </Grid>
          </Segment>

          <Segment>
            <Grid stackable columns={2}>
              <Grid.Column>
                <Header as='h2'>
                  {t('orderDetailsTitle')}
                </Header>
              </Grid.Column>
              <Grid.Column>
                <OrderDetails
                  regionRefractionActive={region_refraction_active}
                  regionRegulated={region_regulated}
                  selectedProducts={this.state.products || []}
                  wearing={this.state.wearing}
                  knowPriors={this.state.knowPriors}
                  onProductsChange={d => this.handleProducts(d)}
                  onWearingChange={d => this.handleWearing(d)}
                  onPriorChange={d => this.handlePriorAwareness(d)}
                />
              </Grid.Column>
            </Grid>
          </Segment>

          {
            (region_regulated || this.state.knowPriors === 'yes') && <Segment>
              <Grid stackable columns={16}>
                <Grid.Column width={6}>
                  <Header as='h2'>
                    {t('priorUpload.title')}
                  </Header>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Item.Group>
                    <Item>
                      <Item.Content>

                        <Item.Header>
                          {t('priorUpload.glasses')}
                          {
                            !region_regulated && <div className='header__note'>
                                {t('priorUpload.unregulatedNote')}
                              </div>
                          }
                        </Item.Header>

                        <GlassesRxDetails
                          regionRefractionActive={region_refraction_active}
                          showGlassesRxForm={this.state.showGlassesRxForm}
                          showErrorPopup={this.state.validations.verificationIsInvalid}
                          handleRxValue={this.handleRxValue}
                          glassesRxFormErrors={this.state.glassesRxFormErrors}
                          removeRx={this.removeRx}
                          addRx={this.addRx}
                          glassesRxValues={this.state.glasses_rx}
                          handleVerificationValue={this.handleVerificationValue}
                          handleCopyEyeValues={this.handleCopyEyeValues} />
                      </Item.Content>
                    </Item>
                  </Item.Group>
                  {
                    region_regulated && <Item.Group>
                      <Item>
                        <Item.Content>
                          <Item.Header>
                            {t('priorUpload.contacts')}
                          </Item.Header>
                          <ContactsRxDetails
                            showContactsRxForm={this.state.showContactsRxForm}
                            handleRxValue={this.handleRxValue}
                            removeRx={this.removeRx}
                            addRx={this.addRx}
                            contactsModels={this.state.contactsModels}
                            contactsRxValues={this.state.contacts_rx}
                            handleCopyEyeValues={this.handleCopyEyeValues}
                            contactsRxFormErrors={this.state.contactsRxFormErrors}
                            contactModelDetails={this.state.contactModelDetails} />
                        </Item.Content>
                      </Item>
                    </Item.Group>
                  }
                </Grid.Column>
              </Grid>
            </Segment>
          }

          { !region_regulated &&
            ( this.state.knowPriors === 'no' || this.state.wearing === 'none') &&
              <Segment
                inverted
                color='blue'
                textAlign='center'
              >
                {t('fullRefractionNote')}
              </Segment>
          }

          <Grid stackable centered>
            <Grid.Column width='8'>
              <Button 
                type='submit' 
                className='branded__button branded__button--primary'
                fluid 
                disabled={this.submitBtnDisabled()}>
                {t("submit")}
              </Button>
            </Grid.Column>
          </Grid>
        </Form>
      </Container>
    )
  }
}

const CreateSessionForm = translate('CreateSessionForm')(CreateSessionFormComponent);

export default CreateSessionForm;
