import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { translate } from 'react-i18next';

class UserDetailsComponent extends Component {
  static propTypes = {
    onNameChange: PropTypes.func.isRequired,
    onDOBChange: PropTypes.func.isRequired,
    handleLanguage: PropTypes.func.isRequired,
    languages: PropTypes.arrayOf(PropTypes.object),
    userFormErrors: PropTypes.object
  };

  render() {
  const { t } = this.props
  
    return (
      <div>
        <Form.Field
          className="phi"
          control={Form.Input}
          label={t('name')}
          onChange={this.props.onNameChange}
          onBlur={this.props.onNameBlur}
          error={!!this.props.userFormErrors.fullName} />
        <Form.Field
          className="phi"
          control={Form.Input}
          label={t('dob')}
          type='date'
          onChange={this.props.onDOBChange}
          onBlur={this.props.onDOBBlur}
          error={!!this.props.userFormErrors.dob}
          />
        <Form.Field
          control={Form.Dropdown}
          fluid
          selection
          search
          label={t('language')}
          options={this.props.languages}
          onChange={this.props.handleLanguage}
          error={!!this.props.userFormErrors.language} />
      </div>
    )
  }
}

const UserDetails = translate('UserDetails')(UserDetailsComponent);

export default UserDetails;