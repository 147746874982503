import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import { isEmpty, flattenDeep } from 'lodash';
import { translate } from 'react-i18next';

function PriorRxFormErrorMessageComponent({ error, errorList, t }) {

  if (!error) return null;

  if(!isEmpty(errorList)) {
    return (
      <Message color='red'>
        <Message.Header>{t('header')}</Message.Header>
        <Message.List items={flattenDeep(errorList)} />
      </Message>
    );
  } else if (error.disqualifications) {
    return (
      <Message color='red'>
        <Message.Header>{t('header')}</Message.Header>
        {t('details')}
        <Message.List items={error.disqualifications} />
      </Message>
    );
  } else {
    return (
      <Message color='red'>
        <Message.Header>{t('header')}</Message.Header>
        <p>{error.error}</p>
      </Message>
    );
  }
};

PriorRxFormErrorMessageComponent.propTypes = {
  error: PropTypes.object,
  errorList: PropTypes.array
};

const PriorRxFormErrorMessage = translate('PriorRxFormErrorMessage')(PriorRxFormErrorMessageComponent);

export default PriorRxFormErrorMessage;