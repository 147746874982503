import React from "react";
import { Link } from "react-router-dom";
import { Message, Table } from "semantic-ui-react";
import { get } from "lodash";
import { translate } from 'react-i18next';

import { isProctor } from "../../actions/authentication";

const SUPPORT_FIELDS = [
  "userKey",
  "patientName",
  "birthdate",
  "status",
  "createdAt",
  "proctorName"
];
const PROCTOR_FIELDS = [
  "patientName",
  "birthdate",
  "status",
  "createdAt",
  "proctorName",
  "storeLocation"
];

function TestTableComponent(props) {
  const { t } = props;
  const tests = get(props, "tests.data", []);
  const headers = isProctor() ? PROCTOR_FIELDS : SUPPORT_FIELDS;
  const Tests = (
    <Table celled>
      <Table.Header>
        <Table.Row>
          {headers.map((header, index) => (
            <Table.HeaderCell key={index}>{t(header)}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {tests.map((test, index) => (
          <TestsTableRow test={test} isProctor={isProctor()} key={index} t={t} />
        ))}
      </Table.Body>
    </Table>
  );

  if (tests.length > 0) {
    return Tests;
  } else
    return <Message info>{t('noResults')}</Message>;
}

export function TestsTableRow({ test, isProctor, t }) {
  return (
    <Table.Row key={test.id}>
      {isProctor ? null : <Table.Cell>{test.partnerUserKey}</Table.Cell>}
      <Table.Cell singleLine className="phi">
        <Link to={`/patients/${test.patientID}`} className="branded__link">
          {test.patientName}
        </Link>
      </Table.Cell>
      <Table.Cell className="phi">{test.patientBirthday}</Table.Cell>
      <Table.Cell>{t(`statusList.${test.testStatus}`)}</Table.Cell>
      <Table.Cell singleLine>{test.testCreatedAt}</Table.Cell>
      <Table.Cell>{test.partnerEmployeeName}</Table.Cell>
      {isProctor ? <Table.Cell>{t('ecpLocation', test.ecpLocationInfo)}</Table.Cell> : null}
    </Table.Row>
  );
}
// Deal with null store ID

const TestTable = translate('TestTable')(TestTableComponent)

export default TestTable;