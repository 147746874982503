import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Message, Menu, Image, Header, Select } from 'semantic-ui-react';
import { get } from 'lodash';
import { translate } from 'react-i18next';
import LanguageService from '../services/LanguageService';

import { getCurrentUser, isProctor, ecpLocationInStore } from '../actions/authentication';

import poweredByLogoSrc from '../media/powered-by-logo.svg';

class LayoutComponent extends Component {
  constructor(props) {
    super(props);

    this.state = { languages: [] }
  }

  componentDidMount() {
    LanguageService.fetchActiveLanguages()
      .then((res) => this.setState({
        languages: res.data.available_languages.map(lng => {
          return { value: lng.locale, text: this.props.t(`languages.${lng.locale}`) }
        })
      })
    )
  }

  render() {
    const { t, children, location } = this.props;

    const currentUser = getCurrentUser();
    const phone = get(currentUser, 'partner.opternative_phone', null);
    const slug = get(currentUser, 'partner.slug', null);
    const image = get(currentUser, 'partner.logo_url', null);
    const partnerName= get(currentUser, 'partner.name', 'Visibly');
    const defaultPhone = '+1 (866) 314-6941';

    return (
      <div>
        <Grid centered stackable className={`branded branded--${slug}`}>
          <Grid.Row>
            <Grid.Column width={12}>
              <Menu stackable borderless className='branded__header'>
                <Menu.Item header>
                  { image ? <Image src={image} spaced className='branded__logo' /> : <Header className='branded__header'>{partnerName}</Header>}
                </Menu.Item>
                <Menu.Item active={location.pathname === "/tests"}><Link to='/tests' className='branded__link branded__link--header'>{t('tests')}</Link></Menu.Item>
                <Menu.Item active={location.pathname === "/patients"}><Link to='/patients' className='branded__link branded__link--header'>{t('patients')}</Link></Menu.Item>
                <NewSessionMenuItem isProctor={isProctor()} ecpLocationInStore={ecpLocationInStore()}  location={location}/>
                <Menu.Menu position='right'><Menu.Item><span className='branded__header'>{t('salutation', {name: `${currentUser.first_name} ${currentUser.last_name}`})}</span></Menu.Item>
                  <Menu.Item><Link to='/logout'className='branded__link branded__link--header'>{t('signOut')}</Link></Menu.Item>
                </Menu.Menu>
              </Menu>
              {children}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={12}>
              <Message>
                <Message.Content as={Grid} columns={2} divided>
                  <Grid.Column width={13}>
                    <Message.Header>
                      {t('help')}
                    </Message.Header>
                    <Message.Content>
                      {t('helpline', {phone: phone || defaultPhone})}
                    </Message.Content>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <Image src={poweredByLogoSrc} size='small' centered className="powered-by"/>
                  </Grid.Column>
                </Message.Content>
              </Message>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={3}>
              <Select
                className='language-select'
                fluid
                selection
                options={this.state.languages}
                defaultValue={t('currentLng')}
                onChange={(event, el) => {
                  LanguageService.postPreferredLanguage(el.value)
                  window.location.search = `locale=${el.value}`;
                }}
              />
            </Grid.Column>
            <Grid.Column width={9} />
          </Grid.Row>
        </Grid>
      </div>
    );
  }

}


function NewSessionMenuItemComponent({ isProctor, ecpLocationInStore, location, t }) {
  if(!isProctor || !ecpLocationInStore) return null; 
  return (
    <Menu.Item active={location.pathname === "/create_session"}>
      <Link to='/create_session' className='branded__link branded__link--header'>
        {t('new')}
      </Link>
    </Menu.Item>
  );
}

const Layout = translate('Layout')(LayoutComponent);
const NewSessionMenuItem = translate('Layout')(NewSessionMenuItemComponent);

export { NewSessionMenuItem };
export default Layout;
