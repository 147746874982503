import React, { Component } from 'react';
import TestForm from '../components/tests_index/TestForm';
import Pagination from '../components/Pagination';
import TestTable from '../components/tests_index/TestTable';
import queryString from 'query-string';
import { VizDimmer, VizDimmable } from '../components/VizDimmer';

import { getOrSetFilter, getOrSetPage } from '../helpers/common'
import { getTestsData } from '../services/index';
import { translate } from 'react-i18next';

import { ecpLocationID } from '../actions/authentication';

const FORM_RESET = {
  started: false,
  awaiting_checkout: false,
  missing_requirement: false,
  support_reviewing: false,
  awaiting_review: false,
  approved: false,
  retake_requested: false,
  referred: false,
  page: 1,
};
const SEARCH_FIELD_NAME = 'search';
const ECP_LOCATION_RADIO_BUTTON_NAME = 'ecp_location';

class ExploreTests extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClearStatusFilters = this.handleClearStatusFilters.bind(this);
    this.requestAndAssignTests = this.requestAndAssignTests.bind(this);
    this.handlePrevPage = this.handlePrevPage.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);

    this.state = {
      loading: false,
      form: this.buildForm(props),
      tests: {
        meta: {},
        data: []
      }
    };
  }

  buildForm(props) {
    return {
      search: props.form.search || '',
      started: getOrSetFilter(props.form, 'started'),
      awaiting_checkout: getOrSetFilter(props.form, 'awaiting_checkout'),
      missing_requirement: getOrSetFilter(props.form, 'missing_requirement'),
      support_reviewing: getOrSetFilter(props.form, 'support_reviewing'),
      awaiting_review: getOrSetFilter(props.form, 'awaiting_review'),
      approved: getOrSetFilter(props.form, 'approved'),
      retake_requested: getOrSetFilter(props.form, 'retake_requested'),
      referred: getOrSetFilter(props.form, 'referred'),
      on_site_patient: getOrSetFilter(props.form, 'on_site_patient'),
      online_patient: getOrSetFilter(props.form, 'online_patient'),
      ecp_location: props.form.ecp_location || ecpLocationID(),
      page: getOrSetPage(props.form),
    };
  }

  async requestAndAssignTests(form) {
    const query = queryString.stringify(form);
    const { t } = this.props;
    this.setState({ loading: true });

    try {
      const tests = await getTestsData(query);
      this.setState({ tests, loading: false });
    } catch (e) {
      alert(t('error'))
    }
  }

  componentWillReceiveProps(props) {
    this.setState({ form: this.buildForm(props) });
    this.requestAndAssignTests(props.form);
  }

  handleChange(event, { name, value, checked }) {
    const submitFormAfterUpdatingState = name !== SEARCH_FIELD_NAME;
    const isCheckboxField = name !== SEARCH_FIELD_NAME && name !== ECP_LOCATION_RADIO_BUTTON_NAME;

    this.setState({
      form: Object.assign(this.state.form, {
        page: 1,
        [name]: isCheckboxField ? checked : value
      })
    });

    if (submitFormAfterUpdatingState) { this.handleSubmit(); };
  }

  handleSubmit(event) {
    this.props.onSubmit(this.state.form);
  }

  handleClearStatusFilters(events) {
    const clearedState = Object.assign(this.state, {
      form: Object.assign(this.state.form, FORM_RESET)
    });

    this.setState(clearedState);
  }

  handlePrevPage(event) {
    if (this.state.form.page <= 1) return;

    const prevPageState = Object.assign(this.state, {
      form: Object.assign(this.state.form, { page: this.state.form.page-1 })
    });

    this.setState(prevPageState);
    this.handleSubmit(event);
  }

  handleNextPage(event) {
    const nextPageState = Object.assign(this.state, {
      form: Object.assign(this.state.form, { page: this.state.form.page+1 })
    });

    this.setState(nextPageState);
    this.handleSubmit(event);
  }

  render() {
    return (
      <VizDimmable dimmed={this.state.loading}>
        <VizDimmer active={this.state.loading} inverted />

        <TestForm
          clearStatuses={this.handleClearStatusFilters}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          {...this.state.form} />

        <TestTable tests={this.state.tests} />

        <Pagination
          handlePrevPage={this.handlePrevPage}
          handleNextPage={this.handleNextPage}
          currentPage={this.state.tests.meta.page} />
      </VizDimmable>
    );
  }
}

const ExploreTestsContainer = translate('ExploreTests')(ExploreTests);

export default ExploreTestsContainer;
