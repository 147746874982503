import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { Message, Container, Divider, Header, Item, Segment, Button, Input } from 'semantic-ui-react';
import { translate } from 'react-i18next';
import { isProctor } from '../../actions/authentication';
import { apiHost } from '../../actions/api';
import { postAuthentication, postShortCode } from '../../services';

class PatientAvailableActionsComponent extends Component {
  static propTypes = {
    path: PropTypes.string,
    userKey: PropTypes.string.isRequired,
    sessionKey: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.authLinkContainerId = `auth-link-${this.props.userKey}-${this.props.sessionKey}`;
    this.state = {
      copied: !typeof document.execCommand === 'function',
      startedTest: false,
      authLink: '',
      authLinkExpiresAt: '',
    };
  }

  copyText () {
    let input = this.authLinkContainer;
    input = (input && input.inputRef) || input;
    if (input && input.select) input.select();
    if (document.execCommand('copy')) this.setState({ copied: true });
  }

  postAuthenticationLink ({ shouldNavigateAfterLinkGenerated = false }) {
    const params = {
      user_key: this.props.userKey,
      session_key: this.props.sessionKey,
    };

    return postAuthentication(params)
      .then((res) => {
        const { link, expires_at } = res;

        if (shouldNavigateAfterLinkGenerated) {
          this.setState({ startedTest: true });
          window.location.assign(link);
        } else {
          this.setState({
            authLink: link,
            authLinkExpiresAt: expires_at,
          });
        }

        return res;
      });
  }

  generateShortCode () {
    const syncLocation = apiHost() + '/sync';
    const params = {
      user_key: this.props.userKey,
      session_key: this.props.sessionKey
    };

    return postShortCode(params).then((res) => {
      const value = res.short_code;

      this.setState({
        shortCode: value,
        syncLocation
      });

      return res;
    });
  }

  render() {
    const { t } = this.props;

    return (
      <Segment>
        <Item.Group>
          <Item>
            <Header size='medium'>{t('header')}</Header>
          </Item>
          <Item>
            <Button
              fluid
              disabled={this.state.startedTest || !!this.state.authLink}
              content={this.props.path ? t('start') : t('loginLink')}
              className='branded__button branded__button--primary'
              onClick={() => this.postAuthenticationLink({ shouldNavigateAfterLinkGenerated: !!this.props.path })}
            />
          </Item>
          <Item>
            {
              this.props.path ? (
                <Container fluid>
                  <span>{t('refreshPrompt')} </span>
                  <Link to={this.props.path} className='branded__link'>
                    {t('refresh')}
                  </Link>
                </Container>
              ) : (
                <Container fluid>
                  {
                    this.state.authLink ? (
                      <Item.Group>
                        <Item>
                          <Container fluid>
                            <Input
                              fluid
                              ref={(input) => { this.authLinkContainer = input }}
                              value={this.state.authLink}
                              action={{
                                icon: this.state.copied ? 'check' : 'copy',
                                onClick: (e) => this.copyText(e),
                                disabled: this.state.copied,
                                className: 'branded__button branded__button--primary'
                              }}
                            />
                          </Container>
                        </Item>
                        <Item>
                          <Container fluid textAlign='center'>
                            <Message warning size='tiny'>
                              {t('expiration', {time: moment(this.state.authLinkExpiresAt).fromNow()})}
                            </Message>
                          </Container>
                        </Item>
                      </Item.Group>
                    ) : ''
                  }
                </Container>
              )
            }
          </Item>
          <Container fluid>
            { isProctor() ? (
              <Item.Group>
                <Item>
                  <Button
                    fluid
                    disabled={!!this.state.shortCode}
                    content={t('loginCode')}
                    className='branded__button branded__button--primary'
                    onClick = {() => this.generateShortCode()}
                  />
                </Item>
                <Item>
                  { this.state.shortCode ? (
                    <Container fluid textAlign='center'>
                      <Message success>
                        <Message.Item as='div'>
                          <Message.Header>
                            {t('url')}
                          </Message.Header>
                          <Message.Content>
                            {this.state.syncLocation}
                          </Message.Content>
                        </Message.Item>
                        <Divider hidden />
                        <Message.Item as='div'>
                          <Message.Header>
                            {t('codeDirection')}
                          </Message.Header>
                          <Message.Content>
                            {this.state.shortCode}
                          </Message.Content>
                        </Message.Item>
                      </Message>
                    </Container> ) : ''
                  }
                </Item>
              </Item.Group> ) : ''
            }
          </Container>
        </Item.Group>
      </Segment>
    );
  }
}

const PatientAvailableActions = translate("PatientAvailableActions")(PatientAvailableActionsComponent)

export default PatientAvailableActions;
