import { apiJSON } from '../actions/api';

const storePreferenceEndpoint = '/api/v2/portal/language'
const activeLanguagesEndpoint = '/api/v2/portal/languages';

class LanguageService {
  static async postPreferredLanguage(lang) {
    const opts = {
      method: 'POST',
      body: JSON.stringify({ lang })
    }

    return apiJSON(storePreferenceEndpoint, opts)
  }

  static async fetchActiveLanguages() {
    return apiJSON(activeLanguagesEndpoint, { method: 'GET' })
  }
}

export default LanguageService;