import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Icon, Grid, Segment, Form, Divider, Button } from 'semantic-ui-react';
import { translate } from 'react-i18next';

class PatientFormComponent extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    search: PropTypes.string,
    api_patient: PropTypes.bool,
    api_lite_patient: PropTypes.bool
  };

  componentDidMount() {
    this.props.onSubmit();
  };

  constructor(props) {
    super(props);

    this.handleAccordionClick = this.handleAccordionClick.bind(this);

    this.state = {
      activeIndex: 0
    };
  };

  handleAccordionClick(e, titleProps) {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { t } = this.props;
    const { activeIndex } = this.state;

    return (
      <Accordion>
        <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleAccordionClick}>
          <h4>
            <Icon name='dropdown' /> {t('search.toggle')}
          </h4>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <Segment>
            <Form onSubmit={this.props.onSubmit}>
              <Form.Group>
                <Form.Input
                  name='search'
                  action={{ content: t('search.button'), className: 'branded__button branded__button--primary' }}
                  width={8}
                  value={this.props.search}
                  label={ t('search.label')}
                  placeholder={ t('search.placeholder')}
                  onChange={this.props.onChange}
                  className="phi" />
              </Form.Group>

              <Divider clearing horizontal section>{ t('filter.header') }</Divider>

              <Grid columns={4} stackable padded doubling>
                <Grid.Row>
                  <Grid.Column>
                    <Form.Checkbox name='api_patient' label={t('filter.api')} checked={this.props.api_patient} onChange={this.props.onChange} />
                    <Button onClick={this.props.clearStatuses} content={t('filter.reset')} className='branded__button branded__button--primary' />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Checkbox name='api_lite_patient' label={t('filter.lite')} checked={this.props.api_lite_patient} onChange={this.props.onChange} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Segment>
        </Accordion.Content>
      </Accordion>
    );
  }
}

const PatientForm = translate('PatientForm')(PatientFormComponent);

export default PatientForm;
