import React from 'react';
import { Dimmer } from 'semantic-ui-react';

export const VizDimmable = props => (
  <Dimmer.Dimmable {...props}>
    { props.children }
  </Dimmer.Dimmable>
);

export const VizDimmer = props => (
  <Dimmer {...props}>
    { props.children }
  </Dimmer>
);
