import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { translate } from 'react-i18next';

import Layout from '../components/Layout';
import ExploreTestsContainer from './ExploreTestsContainer';

class TestIndex extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(form) {
    this.props.history.push('?' + queryString.stringify(form));
  }

  parseQuery(props) {
    return queryString.parse(props.location.search);
  }

  render() {
    const { t } = this.props;
    
    return (
      <Layout location={{pathname: '/tests'}}>
        <h1>{t('header')}</h1>
        <ExploreTestsContainer form={this.parseQuery(this.props)} onSubmit={this.handleSubmit} />
      </Layout>
    );
  }
}

const TestIndexContainer = translate('TestIndex')(withRouter(TestIndex));

export default TestIndexContainer;
