import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Segment, Label, Form } from 'semantic-ui-react';
import { translate } from 'react-i18next';

class ContactsRxDetailsComponent extends PureComponent {

  static propTypes = {
    showContactsRxForm: PropTypes.bool.isRequired,
    handleRxValue: PropTypes.func.isRequired,
    removeRx: PropTypes.func.isRequired,
    addRx: PropTypes.func.isRequired,
    contactsModels: PropTypes.array.isRequired,
    contactsRxValues: PropTypes.shape({
      right_eye: PropTypes.object,
      left_eye: PropTypes.object,
    }).isRequired,
    contactsRxFormErrors: PropTypes.shape({
      right_eye: PropTypes.object,
      left_eye: PropTypes.object,
    }).isRequired,
    contactModelDetails: PropTypes.shape({
      right_eye: PropTypes.shape({
        avail_sph: PropTypes.arrayOf(PropTypes.number),
        avail_cyl: PropTypes.arrayOf(PropTypes.number),
        avail_axis: PropTypes.arrayOf(PropTypes.number),
        avail_add: PropTypes.arrayOf(PropTypes.number),
        avail_bc: PropTypes.arrayOf(PropTypes.number),
        avail_diam: PropTypes.arrayOf(PropTypes.number)
      }).isRequired,
      left_eye: PropTypes.shape({
        avail_sph: PropTypes.arrayOf(PropTypes.number),
        avail_cyl: PropTypes.arrayOf(PropTypes.number),
        avail_axis: PropTypes.arrayOf(PropTypes.number),
        avail_add: PropTypes.arrayOf(PropTypes.number),
        avail_bc: PropTypes.arrayOf(PropTypes.number),
        avail_diam: PropTypes.arrayOf(PropTypes.number)
      }).isRequired
    }).isRequired,
    handleCopyEyeValues: PropTypes.func.isRequired
  };


  render() {
    const { t } = this.props;

    return (
      this.props.showContactsRxForm
        ? (
          <div>
            {
              ['right_eye', 'left_eye'].map((eye,i) => (
                <Segment key={i}>
                  <Label attached='top' size='large'>{ t(eye) }</Label>
                  <ContactsRxFormElements
                    t={t}
                    whichEye={eye}
                    onRxValueChange={this.props.handleRxValue}
                    contactsModels={this.props.contactsModels}
                    contactsRxValues={this.props.contactsRxValues[eye]}
                    onCopyEyeValues={this.props.handleCopyEyeValues}
                    contactsRxFormErrors={this.props.contactsRxFormErrors[eye]}
                    contactModelDetailsForEye={this.props.contactModelDetails[eye]} />
                </Segment>
              ))
            }
            <Button 
              className='toggle-prior-rx-button branded__button branded__button--primary'
              compact 
              floated='right' 
              onClick={(_) => this.props.removeRx('contacts_rx')}>
              {t('remove')}
            </Button>
          </div>
        )
        : 
        (
          <Button 
            className='toggle-prior-rx-button branded__button branded__button--primary' 
            compact 
            floated='right'
            onClick={(_) => this.props.addRx('contacts_rx')}>
            {t('add')}
          </Button>
        )
    );
  }
}

ContactsRxFormElements.propTypes = {
  whichEye: PropTypes.string.isRequired,
  onRxValueChange: PropTypes.func.isRequired,
  contactsModels: PropTypes.array.isRequired,
  contactsRxValues: PropTypes.object.isRequired,
  contactsRxFormErrors: PropTypes.object.isRequired,
  contactModelDetailsForEye: PropTypes.shape({
    avail_sph: PropTypes.arrayOf(PropTypes.number),
    avail_cyl: PropTypes.arrayOf(PropTypes.number),
    avail_axis: PropTypes.arrayOf(PropTypes.number),
    avail_add: PropTypes.arrayOf(PropTypes.number),
    avail_bc: PropTypes.arrayOf(PropTypes.number),
    avail_diam: PropTypes.arrayOf(PropTypes.number)
  }).isRequired,
  onCopyEyeValues: PropTypes.func.isRequired
};

// If this is ever fully spun out into new component, remove t from props and wrap with translate
function ContactsRxFormElements({ t, whichEye, onRxValueChange, contactsModels, contactsRxValues, contactsRxFormErrors, contactModelDetailsForEye, onCopyEyeValues }) {
  const contactModelOptions = dropdownForContactsModels(contactsModels)
  const {
    avail_sph = [],
    avail_cyl = [],
    avail_axis = [],
    avail_add = [],
    avail_bc = [],
    avail_diam = []
  } = contactModelDetailsForEye;

  return (
    <div className='contacts-rx-values-form' id={`${whichEye}-contacts-form`}>
     <Form.Group>
        <Form.Field
           control={Form.Dropdown}
          label={t('ContactsRxFormElements.contactsModel')}
          name='product_key'
          placeholder={t('ContactsRxFormElements.modelPlaceholder')}
          options={contactModelOptions}
          value={contactsRxValues.product_key}
          onChange={(_, { name, value }) => onRxValueChange('contacts_rx', whichEye, name, value)}
          width={8}
          error={!!contactsRxFormErrors.product_key}
          fluid search selection required />
      </Form.Group>
      <Form.Group>
        <Form.Field
          control={Form.Dropdown}
          label={t('ContactsRxFormElements.sph')}
          name='sphere'
          placeholder={t('ContactsRxFormElements.sph')}
          options={contactDropdownOptions(avail_sph, 2)}
          value={contactsRxValues.sphere}
          onChange={(_, { name, value }) => onRxValueChange('contacts_rx', whichEye, name, value)}
          width={4}
          error={!!contactsRxFormErrors.sphere}
          fluid search selection required />
        <Form.Field
          label={t('ContactsRxFormElements.cyl')}
          control={Form.Dropdown}
          name='cylinder'
          placeholder={t('ContactsRxFormElements.cyl')}
          options={contactDropdownOptions(avail_cyl, 2)}
          value={contactsRxValues.cylinder}
          onChange={(_, { name, value }) => onRxValueChange('contacts_rx', whichEye, name, value)}
          width={4}
          fluid search selection />
        <Form.Field
          control={Form.Dropdown}
          label={t('ContactsRxFormElements.axis')}
          name='axis'
          placeholder={t('ContactsRxFormElements.axis')}
          options={contactDropdownOptions(avail_axis)}
          value={contactsRxValues.axis}
          onChange={(_, { name, value }) => onRxValueChange('contacts_rx', whichEye, name, value)}
          width={4}
          fluid search selection />
        <Form.Field
          control={Form.Dropdown}
          label={t('ContactsRxFormElements.add')}
          name='add'
          placeholder={t('ContactsRxFormElements.add')}
          options={contactDropdownOptions(avail_add, 2)}
          value={contactsRxValues.add}
          onChange={(_, { name, value }) => onRxValueChange('contacts_rx', whichEye, name, value)}
          width={4}
          fluid search selection />
      </Form.Group>
      <Form.Group >
        <Form.Field
          control={Form.Dropdown}
          label='BC'
          name='base_curve'
          placeholder='BC'
          options={contactDropdownOptions(avail_bc, 1)}
          value={contactsRxValues.base_curve}
          onChange={(_, { name, value }) => onRxValueChange('contacts_rx', whichEye, name, value)}
          width={4}
          error={!!contactsRxFormErrors.base_curve}
          fluid search selection required />
        <Form.Field
          control={Form.Dropdown}
          label='DIAM'
          name='diameter'
          placeholder='DIAM'
          options={contactDropdownOptions(avail_diam, 1)}
          value={contactsRxValues.diameter}
          onChange={(_, { name, value }) => onRxValueChange('contacts_rx', whichEye, name, value)}
          width={4}
          error={!!contactsRxFormErrors.diameter}
          fluid search selection required />
        <div className="copy-button">
          {
            whichEye === 'right_eye' ?
              (
              	<Button
                  icon='arrow down'
                  content={t('ContactsRxFormElements.copyButton.OS')}
                  labelPosition='right'
                  type='button'
                  onClick={() => onCopyEyeValues('contacts_rx', whichEye)}
                  className='branded__button branded__button--secondary' />
              ) 
              :
              (
              	<Button
                  icon='arrow up'
                  content={t('ContactsRxFormElements.copyButton.OD')}
                  labelPosition='right'
                  type='button'
                  onClick={() => onCopyEyeValues('contacts_rx', whichEye)}
                  className='branded__button branded__button--secondary' />
              )
          }
        </div>
      </Form.Group>
    </div>
  )
}

export function dropdownForContactsModels(models) {
  const blank = { key: Infinity, text: null, value: null };
  const options = models.map((m) => {
    return { key: m.id, text: `${m.brand_name} ${m.name}`, value: m.id };
  });

  return [blank, ...options];
}

export function contactDropdownOptions(values, fixedDecimalPoints = 0) {
  const blank = { key: Infinity, text: null, value: null };
  const options = values.map((value) => {
    const preciseVal = value.toFixed(fixedDecimalPoints);
    return { key: preciseVal, text: preciseVal, value: value };
  })

  return [blank, ...options];
}

const ContactsRxDetails = translate('ContactsRxDetails')(ContactsRxDetailsComponent);

export default ContactsRxDetails;