import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import content from '../i18n';

const lngDetector = new LngDetector();

export default class I18n {
  static initialize (user) {
    lngDetector.addDetector({
      name: 'navigatorParsed',
      lookup() {
        return navigator.language.split('-').join('_');
      }
    });
    lngDetector.addDetector({
      name: 'userPreference',
      lookup() {
        return (user && user.preference.preferred_language);
      }
    });
    const config = {
      detection: {
        order: ['querystring', 'localStorage', 'userPreference', 'navigatorParsed'],
        lookupQuerystring: 'locale',
      },
      debug: true,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
        prefix: '%{',
        suffix: '}'
      },
      resources: content,
    }

    i18n
      .use(lngDetector)
      .init(config);

    this.i18n = i18n;
    return this;
  }
}
