import { apiHost } from './api';
import { get } from 'lodash';

const tokenKey = 'apiToken';
const currentUserKey = 'currentUser';
const ecpLocationKey = 'ecp_location';

const SUPPORT = 'support';
const PROCTOR = 'proctor';

const LOGOUT_PATH = '/portal_logout';

export function getToken() {
  return window.sessionStorage.getItem(tokenKey);
}

export function setToken(value) {
  window.sessionStorage.setItem(tokenKey, value);
}

export function clearToken() {
  window.sessionStorage.removeItem(tokenKey);
  window.sessionStorage.removeItem(currentUserKey);
}

export function getCurrentUser() {
  return JSON.parse(window.sessionStorage.getItem(currentUserKey));
}

export function setCurrentUser(user) {
  window.sessionStorage.setItem(currentUserKey, JSON.stringify(user));
  try {
    window.FS.identify(`${process.env.REACT_APP_ENV}.${user.id}`);
  } catch (e) {
    console.warn(e);
  }  
}

export function isProctor() {
  const user = getCurrentUser();
  if(user) {
    return user.role === PROCTOR;
  }
  return false;
}

export function isSupport() {
  const user = getCurrentUser();
  if(user) {
    return user.role === SUPPORT;
  }
  return false;
}

export function ecpLocationID() {
  const user = getCurrentUser();
  let id = '';

  if (user && user.hasOwnProperty(ecpLocationKey) && user.role === PROCTOR) {
    id = user.ecp_location.id === null ? '' : String(user.ecp_location.id)
  }

  return id;
}

export function ecpLocationInStore() {
  const user = getCurrentUser();

  return get(user, [ecpLocationKey, 'in_store']);
}

export function logoutPathMainApp() {
  return apiHost() + LOGOUT_PATH;
}
