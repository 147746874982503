import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { clearToken, logoutPathMainApp } from '../actions/authentication';

class LogoutContainer extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  };

  componentDidMount() {
    const redirectPath = logoutPathMainApp();
    clearToken();
    window.location.assign(redirectPath);
  }

  render() {
    return null;
  }
}

export default withRouter(LogoutContainer);
