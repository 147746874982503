import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import PatientTable from '../components/patients_index/PatientTable';
import PatientForm from '../components/patients_index/PatientForm';
import Pagination from '../components/Pagination';
import { getOrSetFilter, getOrSetPage } from '../helpers/common';
import { VizDimmer, VizDimmable } from '../components/VizDimmer';

const FORM_RESET = {
  page: 1,
  search: '',
  api_patient: true,
  api_lite_patient: true,
};

class ExplorePatientsContainer extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClearStatusFilters = this.handleClearStatusFilters.bind(this);
    this.handlePrevPage = this.handlePrevPage.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.requestAndAssignPatients = this.requestAndAssignPatients.bind(this);
    this.setFormAttributes = this.setFormAttributes.bind(this);

    this.state = {
      loading: false,
      patientFormAttributes: this.setFormAttributes(),
      patients: {
        meta: {},
        data: []
      }
    };
  }

  setFormAttributes() {
    const query = queryString.parse(this.props.location.search);

    return {
      page: getOrSetPage(query),
      search: query.search || '',
      api_patient: getOrSetFilter(query, 'api_patient'),
      api_lite_patient: getOrSetFilter(query, 'api_lite_patient')
    }
  };

  componentDidMount() {
    this.requestAndAssignPatients();
  };

  async requestAndAssignPatients() {
    try {
      const patients = await this.props.fetchPatients(this.state.patientFormAttributes);
      this.setState({
        patients,
        loading: false
      });
    } catch(e) {
      console.log(e);

      this.setState({
        loading: false
      });
    }
  };

  handleChange(event, { name, value, checked }) {
    const willSubmit = name !== 'search';

    this.setState({
      patientFormAttributes: Object.assign({}, this.state.patientFormAttributes, {
        page: 1,
        [name]: willSubmit ? checked : value
      })
    });

    if (willSubmit) {
      this.handleSubmit();
    }
  }

  handleSubmit() {
    this.setState({
      loading: true
    });

    this.props.history.push('?' + queryString.stringify(this.state.patientFormAttributes));

    this.requestAndAssignPatients();
  };

  handleClearStatusFilters(events) {
    const clearedState = Object.assign({}, this.state, {
      patientFormAttributes: Object.assign({}, this.state.patientFormAttributes, FORM_RESET)
    });

    this.setState(clearedState);
  }

  handlePrevPage(event) {
    if (this.state.patientFormAttributes.page <= 1) return;

    const prevPageState = Object.assign({}, {
      patientFormAttributes: Object.assign({}, this.state.patientFormAttributes, { page: this.state.patientFormAttributes.page - 1 })
    });

    this.setState(prevPageState);
    this.handleSubmit();
  }

  handleNextPage(event) {
    const nextPageState = Object.assign({}, {
      patientFormAttributes: Object.assign(this.state.patientFormAttributes, { page: this.state.patientFormAttributes.page + 1 })
    });

    this.setState(nextPageState);
    this.handleSubmit();
  }

  render () {
    return (
      <VizDimmable dimmed={this.state.loading}>
        <VizDimmer active={this.state.loading} inverted />
          <PatientForm
            clearStatuses={this.handleClearStatusFilters}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            {...this.state.patientFormAttributes} />

          <PatientTable patients={this.state.patients} />

          <Pagination
            handlePrevPage={this.handlePrevPage}
            handleNextPage={this.handleNextPage}
            currentPage={this.state.patients.meta.page} />
      </VizDimmable>
    )
  }
}

export default withRouter(ExplorePatientsContainer);
