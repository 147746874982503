import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { getToken } from '../actions/authentication';

export default function PrivateRoute({ component: Component, ...props }) {

  const render = (props) => getToken() ? <Component {...props} /> : <Redirect to='/' />;

  return <Route {...props} render={render} />;
}
