import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Table,
  Grid,
  Segment,
  Accordion,
  Label,
} from 'semantic-ui-react';
import { get, capitalize, join } from 'lodash';
import { translate } from 'react-i18next';

import PatientAvailableActions from './PatientAvailableActions';
import PriorPrescriptionsForm from './PriorPrescriptionsForm';

import {
  date,
  time,
  patientConditionsEye,
  regcodeTypeName,
  visionTestStatus,
  visionTestEyewear,
  prescriptionEyeShorthand,
  visionTestVisualAcuityResultsI18nKey,
  VISUAL_ACUITY,
  VISUAL_RENEWAL,
} from '../../helpers/common';
import { apiDownload } from '../../actions/api';
import { isProctor } from '../../actions/authentication';

import './Patient.css';

function PatientComponent({ patient, path, t }) {
  if (!patient) return null;

  return (
    <div className='Patient'>
      <h1 className='Patient__Heading'>
        <span className="phi">{patient.first_name} {patient.last_name}</span>
        <span className='Patient__Heading__Detail Patient__Heading__Detail--Left'>{t(patient.registration || 'dtc')}</span>
        <span className='Patient__Heading__Detail Patient__Heading__Detail--Right'>{t('userKey')} {get(patient, 'partner_user.user_key') || <i>None</i>}</span>
      </h1>
      <Table celled stackable>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{t('registeredAt')} {time(patient.created_at)}</Table.Cell>
            <Table.Cell className="phi">{t('registrationCode')} {regcodeTypeName(patient.registration_code) || <i>{t('emptyResult')}</i>}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="phi">{t('email')} {patient.email}</Table.Cell>
            <Table.Cell className="phi">{t('birthdate')} {date(patient.birthdate)} {t('age', {years: patient.age})}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('phone')} {patient.phone_number || <i>{t('missingResult')}</i>}</Table.Cell>
            <Table.Cell>{t('region')} {get(patient, 'region.name') || <i>{t('missingResult')}</i>}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      {
        patient.tests.map((test) => (
          <PatientVisionTest patient={patient} test={test} key={test.id} t={t} />
        ))
      }
      {
        patient.incomplete_sessions.map((session) => (
          <PatientSession patient={patient} path={path} session={session} key={session.id} t={t} />
        ))
      }
    </div>
  );
}

function PatientSession({ patient, session, path, t }) {
  if(!isProctor()) return null;

  const panel = [
    {
      key: 'patient-session',
      title: {
        content: (
          <span>
            {t('session.title')}
            <Label className='Patient__Title__Status'>
              {t('statusFormat', {status: t('session.status'), time: time(session.created_at)})}
            </Label>
            <Label className='Patient__Title__Status'>
              {t('sessionKey')} {test.partner_user_session ? test.partner_user_session.session_key : <i>{t('emptyResult')}</i>}
            </Label>
          </span>
        ),
      },
      content: {
        content: (
          <Grid doubling stackable>
            <Grid.Row>
              <Grid.Column computer={11} tablet={16} mobile={16}>
              </Grid.Column>
              <Grid.Column computer={5} tablet={16} mobile={16}>
                <PatientAvailableActions path={path} sessionKey={session.session_key} userKey={session.session_key} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ),
      },
    },
  ]

  return (
    <Segment className='Patient__VisionTest'>
      <Accordion fluid panels={panel} />
    </Segment>
  );
}

function PatientVisionTest({ patient, test, t }) {
  const sessionKey = test.partner_user_session && test.partner_user_session.session_key;
  const userKey = patient.partner_user && patient.partner_user.user_key;
  const shouldRenderPatientAvailableActions = userKey && sessionKey;
  const isAcuityTest = VISUAL_ACUITY === test.type || VISUAL_RENEWAL === test.type;
  const isOnsite = !!get(test, 'partner_user_session.ecp_location');

  const panel = [
    {
      key: 'patient-vision-test',
      title: {
        content: (
          <span>
            {t(`test.title.${test.type}`, { id: test.id })}
            <Label className='Patient__Title__Status'>
              {t('statusFormat', { status: t(`test.status.${visionTestStatus(test)}`), time: test.created_at })}
            </Label>
            <Label className='Patient__Title__Status'>
              {t('sessionKey')} {test.partner_user_session ? test.partner_user_session.session_key : <i>{t('emptyResult')}</i>}
            </Label>
          </span>
        ),
      },
      content: {
        content: (
          <Grid doubling stackable>
            <Grid.Row>
              <Grid.Column computer={11} tablet={16} mobile={16}>
                <PatientVisionTestDetail patient={patient} test={test} t={t} />
                {
                  (
                    (isAcuityTest || isOnsite) &&
                    (test.exam_results.visual_acuity.right_eye && test.exam_results.visual_acuity.left_eye)
                  ) &&
                    <PatientVisionTestAcuityDetail
                      test={test}
                      t={t}
                    />
                }
                { isAcuityTest && <PatientHealthDetail patient={patient} test={test} t={t} /> }
              </Grid.Column>
              <Grid.Column computer={5} tablet={16} mobile={16}>
                {
                  shouldRenderPatientAvailableActions &&
                    <PatientAvailableActions
                      userKey={userKey}
                      sessionKey={sessionKey}
                    />
                }
                <PatientVisionTestOrderInfo test={test} t={t} />
                <PatientVisionTestDevices test={test} t={t} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ),
      },
    },
  ];

  return (
    <Segment className='Patient__VisionTest'>
      <Accordion fluid panels={panel} />
    </Segment>
  );
}

function PatientVisionTestDetail({ patient, test, t }) {
  const isRefractiveTest = VISUAL_ACUITY !== test.type;

  const correctionObject = visionTestEyewear(test)
  const correctionList = Object.keys(correctionObject).map((correction) => {
    if(correction === 'wearing') {
      return `${t(correctionObject[correction])} (${t('wearing')})`
    } else {
      return t(correctionObject[correction])
    }
  })
  const correctionText = join(correctionList, ' + ')

  return (
    <Segment>
      <h3>{t('details.title')} <span className='Patient_Italicized'>
        {t('provided_by_patient')}
      </span></h3>
      <Table celled stackable>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{t('details.eyewear')}</Table.Cell>
            <Table.Cell>{correctionText || <i>{t('emptyResult')}</i>}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('details.region')}</Table.Cell>
            <Table.Cell>{test.region.name}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('details.storeID')}</Table.Cell>
            <Table.Cell>
              {
                test.partner_user_session && test.partner_user_session.ecp_location ?
                  t('ecpLocation', test.partner_user_session.ecp_location) : <i>{t('emptyResult')}</i>
              }
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('details.PD')}</Table.Cell>
            <Table.Cell negative={test.exam_requirements.pupillary_distance === 'missing'}>{t(`details.${test.exam_requirements.pupillary_distance}`)}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      {isRefractiveTest && <PatientVisionTestPrescriptionsDetail patient={patient} test={test} t={t}/>}
      {isRefractiveTest && <PatientTestNotesDetail test={test} t={t} />}
    </Segment>
  );
}

function PatientVisionTestPrescriptionsDetail({ patient, test, t }) {
  return (
    <div>
      <h3 className='Patient__VisionTestPrescriptions--Heading'>{t('prescriptions.baseHeader')}</h3>
      <Segment.Group>
        <PriorPrescriptionsForm
          test={test}
        />
      </Segment.Group>

      <h3>{t('prescriptions.dispensedHeader')}</h3>
      <Segment.Group>
        {test.dispensed_prescriptions.length === 0 ? <Segment><i>{t('emptyResult')}</i></Segment> : null}
        {test.dispensed_prescriptions.map((prescription) => <Segment key={prescription.id}><DispensedPrescription patient={patient} test={test} prescription={prescription} t={t} /></Segment>)}
      </Segment.Group>
    </div>
  )
}

function PatientTestNotesDetail({ test, t }) {
  return (
    <div>
      <h3 className='Patient__VisionTestNotes--Heading'>{t('notes')}</h3>
      <Segment>{test.notes || <i>{t('emptyResult')}</i>}</Segment>
    </div>
  )
}

function PatientVisionTestAcuityDetail({ test, t }) {

  return (
    <div>
     <Segment>
      <h3>{t('acuity.header')} <span className='Patient_Italicized'>
        {t('correctionNote', { mode: test.mode })} <strong>{t(test.correction_type)}</strong>{t('correctionNoteEnding')}
      </span></h3>
      <Table stackable>
        <Table.Body>
          <Table.Row>
            <Table.Cell>OD:</Table.Cell>
            <Table.Cell><PatientTestVisualAcuityResult test={test} eye={'right'} t={t} /></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>OS:</Table.Cell>
            <Table.Cell><PatientTestVisualAcuityResult test={test} eye={'left'} t={t} /></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
     </Segment>
    </div> 
  ) 
}

function PatientTestVisualAcuityResult({ test, eye, t }) {
  const vaResult = visionTestVisualAcuityResultsI18nKey(test.exam_results.visual_acuity[`${eye}_eye`]);
  return (
    vaResult ? <span>{t(vaResult)}</span> : <i>{t('missingResult')}</i>
  )
}

function PatientHealthDetail({ patient, test, t }) {
  const patientMedications = patient.medications.map((m) => <div key={m.id}>{m.name}</div>);
  const patientGeneralHealthConditions = patient.general_health_conditions.map((ghc) => <div key={ghc.id}>{ghc.name}</div>);
  const patientSymptoms = patient.symptoms.map(symptom => symptom.name);
  const patientSymptomsAndConditionsRight = patientSymptoms.concat(patientConditionsEye(patient.conditions, 'right'));
  const patientSymptomsAndConditionsLeft = patientSymptoms.concat(patientConditionsEye(patient.conditions, 'left'));

  return (
    <Segment>
      <h3>{t('healthDetails.header')} <span className='Patient_Italicized'>
        {t('provided_by_patient')}
      </span></h3>
      <Segment.Group>
        <Segment>
          {patientGeneralHealthConditions.length > 0 ? patientGeneralHealthConditions : <i>{t('emptyResult')}</i>}
        </Segment>
      </Segment.Group>

      <h3>{t('healthDetails.conditions')} <span className='Patient_Italicized'>
        {t('provided_by_patient')}
      </span></h3>
      <Segment.Group>
        <Segment>
          <strong>OD:</strong> {patientSymptomsAndConditionsRight.length > 0 ? patientSymptomsAndConditionsRight.join(', ') : <i>{t('emptyResult')}</i>}<br></br>
          <strong>OS:</strong> {patientSymptomsAndConditionsLeft.length > 0 ? patientSymptomsAndConditionsLeft.join(', ') : <i>{t('emptyResult')}</i>}
        </Segment>
      </Segment.Group>

      <h3>{t('healthDetails.medications')} <span className='Patient_Italicized'>
        {t('provided_by_patient')}
      </span></h3>
      <Segment.Group>
        <Segment>
          {patientMedications.length > 0 ? patientMedications : <i>{t('emptyResult')}</i>}
        </Segment>
      </Segment.Group>

      <PatientTestNotesDetail test={test} t={t} />
    </Segment>
  )
}

class DispensedPrescription extends Component {
  static propTypes = {
    patient: PropTypes.object.isRequired,
    test: PropTypes.object.isRequired,
    prescription: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);


    this.handleDownload = this.handleDownload.bind(this);

    this.state = {
      loading: false
    };
  }

  handleDownload() {
    const { patient, prescription } = this.props;

    this.setState({ loading: true });

    const path = `/api/v2/portal/dispensed_prescriptions/${prescription.id}/document`;
    // revisit, Internationalize?
    const fileName = `${patient.first_name} ${patient.last_name}'s ${capitalize(prescription.type)} Prescription.pdf`;
    const contentType = 'application/pdf';

    apiDownload(path, fileName, contentType).then(() => this.setState({ loading: false }));
  };

  render() {
    const { prescription, t } = this.props;

    const validPeriod = <p>{t('prescriptions.valid')} {date(prescription.issue_date) || <i>{t('missingResult')}</i>} - {date(prescription.expiration_date) || <i>{t('missingResult')}</i>}</p>;

    return (
      <div className='Patient__VisionTest__DispensedPrescription'>
        <p>{t(prescription.type)}</p>
        {prescription.issue_date || prescription.expiration_date ? validPeriod : null}
        <p><code>OD:</code> {prescriptionEyeShorthand(prescription.right_eye)}</p>
        <p><code>OS:</code> {prescriptionEyeShorthand(prescription.left_eye)}</p>
        <Button 
          onClick={this.handleDownload} 
          disabled={this.state.loading} 
          className='branded__button branded__button--primary' 
          loading={this.state.loading}>
          {t('prescriptions.downloadCTA')}
        </Button>
      </div>
    );
  }
}

function PatientVisionTestOrderInfo({ test, t }) {
  const partner = get(test, 'partner_user_session.partner');
  const partnerOrder = get(test, 'partner_user_session.partner_order');
  const optOrder = get(test, 'order');

  return (
    <Segment>
      <h3>{t('order.header')}</h3>
      { partnerOrder && <h4>{ t('order.partner.header' ,{partnerName: partner.name}) }</h4> }
      { partnerOrder && <PatientVisionTestPartnerOrder partnerOrder={ partnerOrder } t={t} /> }
      <h4>{ t('order.visibly.header') }</h4>
      <PatientVisionTestOpternativeOrder optOrder={ optOrder } t={t} />
    </Segment>
  );
}

function PatientVisionTestPartnerOrder({ partnerOrder, t }) {
  const suppliedProduct = get(partnerOrder, 'products')[0];
  const chargedValue = get(partnerOrder, 'charged_value');

  return(
    <Table stackable>
      <Table.Body>
        <Table.Row>
          <Table.Cell width={4}>{t('order.purchased')}</Table.Cell>
          <Table.Cell>{ suppliedProduct ? <span>{ t(`order.${suppliedProduct.slug}`) }</span> : <i>{t('missingResult')}</i> }</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>{t('order.paid')}</Table.Cell>
          <Table.Cell>{ chargedValue ? <span>{ chargedValue }</span> : <i>{t('missingResult')}</i> }</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}

function PatientVisionTestOpternativeOrder({ optOrder, t }) {
  const product = get(optOrder, 'products', [])[0];
  const coupon = get(optOrder, 'coupons', [])[0];
  const transactionsAmount= get(optOrder, 'transactions_total');
  const discountsAmount = get(optOrder, 'discounts_total');

  return(
    <Table stackable>
      <Table.Body>
        <Table.Row>
          <Table.Cell width={4}>{t('order.purchased')}</Table.Cell>
          <Table.Cell>{ product ? <span>{ t(`order.${product.slug}`) }</span> : <i>{t('missingResult')}</i>}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>{t('order.paid')}</Table.Cell>
          <Table.Cell>{ transactionsAmount ? <span>{ transactionsAmount }</span> : <i>{t('missingResult')}</i> }</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>{t('order.visibly.discount')}</Table.Cell>
          <Table.Cell>{ discountsAmount ? <span>{ discountsAmount }</span> : <i>{t('missingResult')}</i> }</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>{t('order.visibly.coupon')}</Table.Cell>
          <Table.Cell>{ coupon ? <span>{ coupon.token }</span> : <i>{t('emptyResult')}</i> }</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}

function PatientVisionTestDevices({ test, t }) {
  return (
    <Segment>
      <h3>{t('device.header')}</h3>
      <Table stackable>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{t('device.duration')}</Table.Cell>
            <Table.Cell>{test.duration_minutes ? <span>{t('device.durationResult', {time: test.duration_minutes})}</span> : <i>{t('missingResult')}</i>}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('device.display')}</Table.Cell>
            <Table.Cell>{test.device_sessions.display ? <span>{test.device_sessions.display.browser}</span> : <i>{t('missingResult')}</i>}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('device.remote')}</Table.Cell>
            <Table.Cell>{test.device_sessions.remote ? <span>{test.device_sessions.remote.browser}</span> : <i>{t('missingResult')}</i>}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Segment>
  );
}

const Patient = translate("Patient")(PatientComponent);

export default Patient;
