import React from 'react';
import { Link } from 'react-router-dom';
import { Message, Table } from 'semantic-ui-react';
import { get } from 'lodash';
import { translate } from 'react-i18next';

import { date, time } from '../../helpers/common';

const PATIENT_TABLE_HEADER_KEYS = [
  'userKey',
  'patientName',
  'birthdate',
  'signedUpAt'
]

function PatientTableComponent (props) {
  const patients = get(props, 'patients.data', []);
  const { t } = props;

  if (patients.length === 0) {
    return (
      <Message info>
        {t('empty')}
      </Message>
    )
  }

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          {
            PATIENT_TABLE_HEADER_KEYS.map((headerKey, index) =>
              <Table.HeaderCell key={ index }>{ t(headerKey) }</Table.HeaderCell>
            )
          }
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {
          patients.map((patient) =>
            <Table.Row key={ patient.id }>
              <Table.Cell>{ patient.partner_user_key || '\u2014' }</Table.Cell>
              <Table.Cell singleLine className="phi">
                <Link to={ `/patients/${patient.id}` } className='branded__link'>
                  { patient.first_name } { patient.last_name }
                </Link>
              </Table.Cell>
              <Table.Cell className="phi">{ date(patient.birthday) }</Table.Cell>
              <Table.Cell singleLine>{ time(patient.created_at) }</Table.Cell>
            </Table.Row>
          )
        }
      </Table.Body>
    </Table>
  )
}

const PatientTable = translate("PatientTable")(PatientTableComponent);

export default PatientTable;