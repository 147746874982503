import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { apiHost } from '../actions/api';
import { getToken } from '../actions/authentication';

class DefaultContainer extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  };

  componentDidMount() {
    if (getToken()) {
      this.props.history.push('/search');
    } else {
      window.location.href = apiHost();
    }
  }

  render() {
    return null;
  }
}

export default withRouter(DefaultContainer);
