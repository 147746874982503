import React from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';

import PrivateRoute from '../components/PrivateRoute';
import LoginContainer from './Login';
import LogoutContainer from './Logout';
import TestIndexContainer from './TestIndex';
import PatientShowContainer from './PatientShow';
import PatientIndexContainer from './PatientIndex';
import CreateSessionContainer from './CreateSession';
import DefaultContainer from './Default';
import InactivityContainer from './Inactivity';
import AnalyticsContainer from './Analytics';

export default function RootContainer() {
  return (
    <HashRouter>
      <AnalyticsContainer>
        <InactivityContainer>
          <Switch>
            <Route path='/login' exact component={LoginContainer} />
            <Route path='/logout' exact component={LogoutContainer} />
            <PrivateRoute path='/tests' exact component={TestIndexContainer} />
            <PrivateRoute path='/patients' exact component={PatientIndexContainer} />
            <PrivateRoute path='/patients/:id' exact component={PatientShowContainer} />
            <PrivateRoute path='/create_session' exact component={CreateSessionContainer} />
            <Route component={DefaultContainer} />
          </Switch>
        </InactivityContainer>
      </AnalyticsContainer>
    </HashRouter>
  );
}
