import merge from 'lodash/merge';
import download from 'downloadjs';

import { getToken, clearToken } from './authentication';

const hosts = {
  production: 'https://app.govisibly.com',
  staging: 'https://staging.govisibly.com',
  development: 'http://localhost:5000'
};

export function apiHost() {
  const env = process.env.REACT_APP_ENV;
  const stored = window.sessionStorage.getItem('apiHost');

  return stored || hosts[env] || hosts.development;
}

export function api(path, options) {
  const url = apiHost() + path;
  const defaults = {
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    }
  };

  return fetch(url, merge(options, defaults));
}

export function apiJSON(path, options) {
  const defaults = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  return api(path, merge(options, defaults)).then(async (response) => {
    const json = await response.json();
    if (response.ok) return json;
    if (response.status === 401) clearToken();
    return Promise.reject(json);
  });
}

export function apiBlob(path, options) {
  return api(path, options).then((response) => {
    if (response.ok) return response.blob();
    return Promise.reject(response);
  });
}

export function apiDownload(path, fileName, contentType, options) {
  return apiBlob(path, options).then((blob) => {
    download(blob, fileName, contentType);
    return blob;
  });
}
