import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class InactivityContainer extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    intervalPeriod: PropTypes.number.isRequired,
    inactivityPeriod: PropTypes.number.isRequired
  };

  static defaultProps = {
    intervalPeriod: 60 * 1000, // 1 minute
    inactivityPeriod: 60 * 60 * 1000 // 1 hour
  };

  constructor(props) {
    super(props);

    this.checkActivity = this.checkActivity.bind(this);
    this.handleInactive = this.handleInactive.bind(this);

    this.state = { timestamp: Date.now() };
  }

  componentDidMount() {
    this.setState({ interval: setInterval(this.checkActivity, this.props.intervalPeriod) });
  }

  componentWillReceiveProps() {
    this.setState({ timestamp: Date.now() });
  }

  checkActivity() {
    const timestamp = this.state.timestamp;

    if (timestamp == null || Date.now() - timestamp < this.props.inactivityPeriod) return;

    this.handleInactive();
  }

  handleInactive() {
    clearInterval(this.state.interval);

    this.props.history.push('/logout');
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default withRouter(InactivityContainer);
