import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Segment,
  Label,
  Form,
  List,
  Icon,
} from 'semantic-ui-react';
import { translate } from 'react-i18next';
import { dropdownOptions } from '../../helpers/common';

const VISUAL_ACUITY_EXAM_TYPE = 'VisualAcuityRenewalExam';

export class GlassesRxDetailsComponent extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    regionRefractionActive: PropTypes.bool,
    examType: PropTypes.string,
    showGlassesRxForm: PropTypes.bool.isRequired,
    showErrorPopup: PropTypes.bool,
    handleRxValue: PropTypes.func.isRequired,
    glassesRxFormErrors: PropTypes.shape({
      right_eye: PropTypes.object,
      left_eye: PropTypes.object,
    }).isRequired,
    removeRx: PropTypes.func.isRequired,
    addRx: PropTypes.func.isRequired,
    glassesRxValues: PropTypes.shape({
      right_eye: PropTypes.object,
      left_eye: PropTypes.object,
    }).isRequired,
    handleVerificationValue: PropTypes.func.isRequired,
    handleCopyEyeValues: PropTypes.func.isRequired,
  };


  render() {
    const { t, regionRefractionActive, examType } = this.props;
    const verificationMethods = regionRefractionActive && examType !== VISUAL_ACUITY_EXAM_TYPE ?
      ['auto-refractor', 'lensometer', 'prior-rx'] :
      ['lensometer', 'prior-rx'];

    return (
      this.props.showGlassesRxForm
        ? (
          <div>
            <Icon.Group>
              {t('sourceClassification.title')}
              <Icon color='red' name='asterisk' size='mini' />
            </Icon.Group>
            <div>
              <List className={`${this.props.showErrorPopup ? 'form-popup__box form-list__verification' : 'form-list__verification'}`} horizontal>
                {
                  verificationMethods.map((method, i) => (
                    <List.Item key={i}>
                      <input id={method} value={method === 'prior-rx' ? 'verified' : method} type='radio' name='handleVerificationValue' onClick={() => this.props.handleVerificationValue(method === 'prior-rx' ? 'verified' : method)} />
                      <label htmlFor={method}>
                        <Label pointing='left' size='large'>{t(`sourceClassification.${method}`)}</Label>
                      </label>
                    </List.Item>
                  ))
                }
              </List>
              <div className={`form-popup ${this.props.showErrorPopup ? 'form-popup--active' : ''}`}>
                <div className='form-popup__content'>
                  {t('sourceClassification.error')}
                </div>
              </div>

            </div>
            {
              ['right_eye', 'left_eye'].map((eye, i) => (
                <Segment key={i}>
                  <Label attached='top' size='large'>{ t(eye) }</Label>
                  <GlassesRxFormElements
                    t={t}
                    whichEye={eye}
                    onRxValueChange={this.props.handleRxValue}
                    glassesRxValues={this.props.glassesRxValues[eye]}
                    onCopyEyeValues={this.props.handleCopyEyeValues}
                    glassesRxFormErrors={this.props.glassesRxFormErrors[eye]}
                  />
                </Segment>
              ))
            }
            <Button
              className='toggle-prior-rx-button branded__button branded__button--primary'
              compact
              floated='right'
              onClick={() => this.props.removeRx('glasses_rx')}
            >
              {t('remove')}
            </Button>
          </div>
        ) : (
          <Button
            className='toggle-prior-rx-button branded__button branded__button--primary'
            compact
            floated='right'
            onClick={() => this.props.addRx('glasses_rx')}
          >
            {t('add')}
          </Button>
        )
    );
  }
}

const sphOptions = dropdownOptions(-10, 3.5, 0.25, 2);
const cylOptions = dropdownOptions(-3, 0, 0.25, 2);
const axisOptions = dropdownOptions(1, 180, 1, 0);
const addOptions = dropdownOptions(0, 4.00, 0.25, 2);
const pdOptions = dropdownOptions(10, 50, 0.5, 1);

GlassesRxFormElements.propTypes = {
  t: PropTypes.func.isRequired,
  whichEye: PropTypes.string.isRequired,
  onRxValueChange: PropTypes.func.isRequired,
  /* eslint-disable react/forbid-prop-types */
  glassesRxValues: PropTypes.object.isRequired,
  /* eslint-enable react/forbid-prop-types */
  onCopyEyeValues: PropTypes.func.isRequired,
  /* eslint-disable react/forbid-prop-types */
  glassesRxFormErrors: PropTypes.object.isRequired,
  /* eslint-enable react/forbid-prop-types */
};

// If this is ever fully spun out into new component, remove t from props and wrap with translate
function GlassesRxFormElements({
  t,
  whichEye,
  onRxValueChange,
  glassesRxValues,
  onCopyEyeValues,
  glassesRxFormErrors,
}) {
  return (
    <div>
      <Form.Group>
        <Form.Field
          control={Form.Dropdown}
          fluid
          label={t('GlassesRxFormElements.sph')}
          name='sphere'
          options={sphOptions}
          value={glassesRxValues.sphere}
          onChange={(_, { name, value }) => onRxValueChange('glasses_rx', whichEye, name, value)}
          placeholder={t('GlassesRxFormElements.sph')}
          width={4}
          error={!!glassesRxFormErrors.sphere}
          search
          selection
          required
        />
        <Form.Field
          control={Form.Dropdown}
          fluid
          label={t('GlassesRxFormElements.cyl')}
          name='cylinder'
          options={cylOptions}
          value={glassesRxValues.cylinder}
          onChange={(_, { name, value }) => onRxValueChange('glasses_rx', whichEye, name, value)}
          placeholder={t('GlassesRxFormElements.cyl')}
          width={4}
          search
          selection
        />
        <Form.Field
          control={Form.Dropdown}
          label={t('GlassesRxFormElements.axis')}
          fluid
          name='axis'
          options={axisOptions}
          value={glassesRxValues.axis}
          onChange={(_, { name, value }) => onRxValueChange('glasses_rx', whichEye, name, value)}
          placeholder={t('GlassesRxFormElements.axis')}
          width={4}
          search
          selection
        />
        <Form.Field
          control={Form.Dropdown}
          label={t('GlassesRxFormElements.add')}
          fluid
          name='add'
          options={addOptions}
          value={glassesRxValues.add}
          onChange={(_, { name, value }) => onRxValueChange('glasses_rx', whichEye, name, value)}
          placeholder={t('GlassesRxFormElements.add')}
          width={4}
          search
          selection
        />
      </Form.Group>
      <Form.Group>
        <Form.Field
          control={Form.Dropdown}
          label={t('GlassesRxFormElements.pdLabel')}
          fluid
          name='pupillary_distance'
          placeholder={t('GlassesRxFormElements.pd')}
          options={pdOptions}
          value={glassesRxValues.pupillary_distance}
          onChange={(_, { name, value }) => onRxValueChange('glasses_rx', whichEye, name, value)}
          search
          selection
          width={4}
        />
        <div className="copy-button">
          {
            whichEye === 'right_eye' ?
              (
                <Button
                  icon='arrow down'
                  content={t('GlassesRxFormElements.copyButton.OS')}
                  labelPosition='right'
                  type='button'
                  onClick={() => onCopyEyeValues('glasses_rx', whichEye)}
                  className='branded__button branded__button--secondary'
                />
              ) : (
                <Button
                  icon='arrow up'
                  content={t('GlassesRxFormElements.copyButton.OD')}
                  labelPosition='right'
                  type='button'
                  onClick={() => onCopyEyeValues('glasses_rx', whichEye)}
                  className='branded__button branded__button--secondary'
                />
              )
          }
        </div>
      </Form.Group>
    </div>
  );
}

const GlassesRxDetails = translate('GlassesRxDetails')(GlassesRxDetailsComponent);

export default GlassesRxDetails;
