import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { Form, Radio } from 'semantic-ui-react';
import { translate } from 'react-i18next';

OrderDetailsComponent.propTypes = {
  onProductsChange: PropTypes.func.isRequired,
  regionRefractionActive: PropTypes.bool,
  regionRegulated: PropTypes.bool,
  selectedProducts: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

function OrderDetailsComponent({
    t,
    selectedProducts,
    onProductsChange,
    regionRefractionActive,
    regionRegulated,
    wearing,
    knowPriors,
    onWearingChange,
    onPriorChange
  }) {

var availableProducts
if (!regionRegulated) {
availableProducts = [ ['glasses'] ];
} else if (!regionRefractionActive) {
  availableProducts = [ ['glasses'], ['contacts'] ];
} else {
  availableProducts = [ ['glasses'], ['contacts'], ['glasses', 'contacts'] ];
}
  const regulation = regionRegulated ? 'high' : 'low';
  const wearingOptions = ['glasses', 'none'];
  const knowingPriorOptions = ['yes', 'no'];
  const selectedProductTypes = selectedProducts.map(p => p.type);

  return (
    <div>
      <Form.Field>
        <label>
          {t(`${regulation}.header`)}
        </label>
        {
          availableProducts.length > 1 ? availableProducts.map((types, i) => (
            <Radio
              key={i}
              name='group'
              value={types.join(' and ')}
              label={t(types.join('_'))}
              checked={isEqual(types, selectedProductTypes)}
              onChange={() => onProductsChange(types.map(type => ({ type })))}
              className='radio'
            />
          )) :
          wearingOptions.map((response, i) => (
            <Radio
              key={i}
              name='wearing'
              value={response}
              label={t(response)}
              checked={isEqual(response, wearing)}
              onChange={() => onWearingChange(response)}
              className='radio'
            />
          ))
        }
      </Form.Field>
      {
        wearing === 'glasses' && <Form.Field>
          <label>
            {t('priorHeader')}
          </label>
          {
            knowingPriorOptions.map((response, i) => (
              <Radio
                key={i}
                name='priors'
                value={response}
                label={t(response)}
                checked={isEqual(response, knowPriors)}
                onChange={() => onPriorChange(response)}
                className='radio'
              />
            ))
          }
        </Form.Field>
      }
    </div>
  );
}

const OrderDetails = translate('OrderDetails')(OrderDetailsComponent);

export default OrderDetails;
