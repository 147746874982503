import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

import { REACT_APP_GA_TRACKING } from '../helpers/env';

if (REACT_APP_GA_TRACKING) {
  ReactGA.initialize(REACT_APP_GA_TRACKING);
}

class AnalyticsContainer extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    if (REACT_APP_GA_TRACKING) {
      this.pageView(this.props.location);
      this.props.history.listen((location) => this.pageView(location));
    }
  }

  pageView(location) {
    const fullPath = location.pathname + location.search;
    ReactGA.set({ page: fullPath });
    ReactGA.pageview(fullPath);
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default withRouter(AnalyticsContainer);
