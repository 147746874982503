import { apiJSON } from '../actions/api';

const contactsModelsEndpoint = '/api/v2/portal/contacts_models';

class ContactModelService {
  static async getContactsModels() {
    return apiJSON(contactsModelsEndpoint, { method: 'GET' })
  }

  static async getContactModel(id) {
    return apiJSON(`/api/v2/portal/contacts_models/${id}`, { method: 'GET' })
  }
}

export default ContactModelService;
