import moment from 'moment-timezone';
import { reduce, merge, range, join, includes, forIn, keys, get } from 'lodash';
import { getCurrentUser } from '../actions/authentication';

const RX_FIELD_ATTRIBUTES = {
  sphere: 'SPH',
  cylinder: 'CYL',
  axis: 'AXIS',
  base_curve: 'BC',
  diameter: 'DIAM',
  product_key: 'CONTACTS MODEL'
};

export const VISUAL_ACUITY = 'VisualAcuityExam';

export const VISUAL_RENEWAL = 'VisualAcuityRenewalExam'

export function date(value) {
  return value ? moment(value).format('MM/DD/YYYY') : null;
}

export function time(value) {
  return value ? moment(value).format('MM/DD/YYYY hh:mm A') : null;
}

export function testsPresenter(test) {
  return {
    'id': test.id,
    'patientID': test.patient.id,
    'patientName': test.patient.first_name + ' ' + test.patient.last_name,
    'partnerUserKey': get(test, 'patient.partner_user_key', '\u2014'),
    'patientBirthday': date(test.patient.birthday),
    'testStatus': visionTestStatus(test),
    'testCreatedAt': time(test.created_at),
    'partnerEmployeeName': partnerEmployeeName(test),
    'ecpLocationInfo': ecpLocationInfo(test),
  }
}

export function partnerEmployeeName(test) {
  let firstName = get(test, 'partner_user_session.partner_employee.first_name', '');
  let lastName = get(test, 'partner_user_session.partner_employee.last_name', '');

  return firstName && lastName ? firstName + ' ' + lastName : '';
}

export function ecpLocationInfo(test) {
  const ecpLocation = get(test, 'partner_user_session.eye_care_provider_location');
  if (ecpLocation === null || ecpLocation === undefined) {
    return null;
  }
  const store_id = get(ecpLocation, 'store_id');
  const title = get(ecpLocation, 'title');

  return { store_id, title };
}

export function regcodeTypeName(regcode) {
  return regcode ? regcode.type_name : null;
}

export function visionTestStatus(visionTest) {
  const status = visionTest.status
  if (status === 'referred') {
    const reason = !!visionTest.reject_reason ? `${visionTest.reject_reason}` : 'default';
    return `${status}.${reason}`;
  } else return status;
}

// revisit, for simplification/removal
export function visionTestEyewear(visionTest) {
  const parts = {};

  const correctionType = includes(['neither', null], visionTest.correction_type) ? null : visionTest.correction_type;
  const primaryCorrectionType = includes(['neither', null], visionTest.primary_correction_type) ? null : visionTest.primary_correction_type;

  if (correctionType) {
    parts.wearing = correctionType
  }

  if (primaryCorrectionType === 'both' && correctionType === 'contacts') {
    parts.secondary = 'glasses'
  } else if (primaryCorrectionType === 'both' && correctionType === 'glasses') {
    parts.secondary = 'contacts'
  } else if (primaryCorrectionType === 'both' && !correctionType) {
    parts.secondary = 'both'
  } else if (primaryCorrectionType && primaryCorrectionType !== correctionType) {
    parts.secondary = primaryCorrectionType;
  }

  return parts;
}

export function prescriptionVerification(prescription) {
  const method = prescription.confirmation_method;

  const currentUser = getCurrentUser();

  if (method === 'none' || method == null) {
    return null;
  } else if (method === 'partner' && currentUser.partner) {
    return currentUser.partner.name;
  } else {
    return method;
  }
}

export function prescriptionEyeShorthand(prescriptionEye) {
  const parts = [];

  parts.push(prescriptionEye.sphere);
  parts.push(prescriptionEye.cylinder);

  if (prescriptionEye.cylinder !== 'DS') {
    parts.push('x ' + prescriptionEye.axis);
  }

  if (prescriptionEye.add) {
    parts.push(prescriptionEye.add);
  }

  if (prescriptionEye.contacts) {
    parts.push('\u2014 ' + prescriptionEye.contacts);
    parts.push(prescriptionEye.base_curve);
    parts.push(prescriptionEye.diameter);
  }

  if (prescriptionEye.dominance) {
    parts.push('\u2014 ' + prescriptionEye.dominance);
  }

  return join(parts, ' ');
}

export function dropdownOptions(start, end, step, fixedDecimalPoints = 0) {
  const blank = { key: Infinity, text: null, value: null };
  const options = range(start, end+step, step).map((value) => {
    const preciseVal = value.toFixed(fixedDecimalPoints);
    return { key: preciseVal, text: preciseVal, value: preciseVal };
  });

  return [blank, ...options];
}

export function visionTestVisualAcuityResultsI18nKey(visualAcuityResults) {
  if (visualAcuityResults && (visualAcuityResults.vdap_output || visualAcuityResults.sdap_output)) {
    return 'acuity.within_vdap_limit';
  } else {
    return 'acuity.exceeds_vdap_limit';
  }
}

export function visionTestVisualAcuityResultsDescriptorI18nKey(visualAcuityResults) {
  if (!visualAcuityResults.left_eye || !visualAcuityResults.right_eye){
    return null
  }
  const worstDenominator = visualAcuityResults.left_eye.denominator > visualAcuityResults.right_eye.denominator ? 
    visualAcuityResults.left_eye.denominator : 
    visualAcuityResults.right_eye.denominator;
    
  if (worstDenominator < 20) {
    return 'acuity.results.great';
  }
  else if (worstDenominator >= 20 && worstDenominator < 40) {
    return 'acuity.results.good';
  }
  else {
    return 'acuity.results.fair';
  }
};

export function visionTestColorVisionDeficiency(colorVisionResults) {
  if (colorVisionResults && colorVisionResults.red_green_pass === true) {
    return 'colorVisionResults.passed';
  } else if (colorVisionResults && colorVisionResults.red_green_pass === false) {
    return 'colorVisionResults.failed';
  };
  
  return 'missingResult';
};

export function patientConditionsEye(conditions, eye) {
  return conditions.reduce((filtered, condition) => {
    if (condition.eye === eye || condition.eye === 'both') {
      filtered.push(condition.name);
    }
    return filtered;
  }, []);
};

export function getOrSetFilter(form, flag) {
  if (form[flag] === undefined) {
    return true
  } else return form[flag] === 'true';
}

export function getOrSetPage(form) {
  if (form.page === undefined) {
    return 1;
  } else return parseInt(form.page, 10);
}

export function errorsArray(whichRx, errors) {
  const allErrors = [];

  forIn(errors, (errs, eye) => {
    if (keys(errs).length > 0) {
      forIn(errs, (value, field) => {
        let errMsg = '';
        whichRx === 'glasses' ?
          errMsg += `Glasses RX ${eye === 'right_eye' ? 'OD' : 'OS'} - ${RX_FIELD_ATTRIBUTES[field]} is required.` :
          errMsg += `Contacts RX ${eye === 'right_eye' ? 'OD' : 'OS'} - ${RX_FIELD_ATTRIBUTES[field]} is required.`
        allErrors.push(errMsg);
      })
    }
  });

  return allErrors;
};

export function parseErrors(errors) {
  return reduce(keys(errors), (memo, key) => {
    return merge(
      {},
      memo,
      parseError(key, errors[key])
    );
  }, {});
}

export function parseError(key, value) {
  return key
    .split(/\[|\]/)
    .filter(fragment => fragment.length > 0)
    .reduceRight((memo, key) => ({ [key]: memo }), value);
}
