import { apiJSON } from '../actions/api';
import { testsPresenter } from '../helpers/common';

export function postAuthentication(body) {
  const opts = {
    body: JSON.stringify(body),
    method: 'POST',
  };

  return apiJSON('/api/v2/partners/authentications', opts);
}

export function postShortCode(body) {
  const path = '/api/v2/portal/short_codes/';
  const opts = {
    body: JSON.stringify(body),
    method: 'POST',
  };

  return apiJSON(path, opts);
}

export function getPatientData(id) {
  return apiJSON(`/api/v2/portal/patients/${id}`);
}

export async function getTestsData(query) {
  try {
    let data = await apiJSON(`/api/v2/portal/tests?${query}`);    
    data.data = data.data.map(testsPresenter);
    return Promise.resolve(data);
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}