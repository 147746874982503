import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Icon, Grid, Segment, Form, Divider, Button } from 'semantic-ui-react';
import { translate } from 'react-i18next';

import { isProctor, ecpLocationID } from '../../actions/authentication';

class TestFormComponent extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    search: PropTypes.string,
    awaiting_review: PropTypes.bool,
    missing_requirement: PropTypes.bool,
    support_reviewing: PropTypes.bool,
    awaiting_checkout: PropTypes.bool,
    approved: PropTypes.bool,
    retake_requested: PropTypes.bool,
    referred: PropTypes.bool,
    started: PropTypes.bool,
    ecp_location: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.handleAccordionClick = this.handleAccordionClick.bind(this);

    this.state = {
      activeIndex: 0
    };
  };

  componentDidMount() {
    this.props.onSubmit();
  };

  handleAccordionClick(e, titleProps) {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { t } = this.props;
    const { activeIndex } = this.state;

    return (
      <Accordion>
        <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleAccordionClick}>
          <h4>
            <Icon name='dropdown' />{t('header')}
          </h4>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <Segment>
            <Form onSubmit={this.props.onSubmit}>
              <Form.Group>
                <Form.Input
                  name='search'
                  action={{ content: t('cta'), className: 'branded__button branded__button--primary' }}
                  width={8}
                  placeholder={t('placeholder')}
                  label={t('label')}
                  value={this.props.search}
                  onChange={this.props.onChange}
                  className="phi" />
              </Form.Group>

              <Divider clearing horizontal section>{t('filter.statusHeader')}</Divider>

              <Grid columns={4} stackable padded doubling>
                <Grid.Row>
                  <Grid.Column>
                    <Form.Checkbox name='started' label={t('filter.started')} checked={this.props.started} onChange={this.props.onChange} />
                    <Form.Checkbox name='awaiting_review' label={t('filter.awaiting_review')} checked={this.props.awaiting_review} onChange={this.props.onChange} />
                  </Grid.Column>

                  <Grid.Column>
                    <Form.Checkbox name='awaiting_checkout' label={t('filter.awaiting_checkout')} checked={this.props.awaiting_checkout} onChange={this.props.onChange} />
                    <Form.Checkbox name='retake_requested' label={t('filter.retake_requested')} checked={this.props.retake_requested} onChange={this.props.onChange} />
                  </Grid.Column>

                  <Grid.Column>
                    <Form.Checkbox name='missing_requirement' label={t('filter.missing_requirement')} checked={this.props.missing_requirement} onChange={this.props.onChange} />
                    <Form.Checkbox name='approved' label={t('filter.approved')} checked={this.props.approved} onChange={this.props.onChange} />
                  </Grid.Column>

                  <Grid.Column>
                    <Form.Checkbox name='support_reviewing' label={t('filter.support_reviewing')} checked={this.props.support_reviewing} onChange={this.props.onChange} />
                    <Form.Checkbox name='referred' label={t('filter.referred')} checked={this.props.referred} onChange={this.props.onChange} />
                  </Grid.Column>

                  <Grid.Column>
                    <br></br>
                    <Button onClick={this.props.clearStatuses} content={t('filter.clear_all')} className='branded__button branded__button--primary' />
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Grid columns={1}>
                <Grid.Row>
                  {
                    isProctor() ? (
                      <Grid.Column>
                        <Divider clearing horizontal section>{t('filter.locationHeader')}</Divider>

                        <Grid columns={4} stackable padded doubling>
                          <Grid.Row>
                            <Grid.Column>
                              <Form.Radio
                                label={t('filter.myLocation')}
                                name='ecp_location'
                                value={ecpLocationID()}
                                checked={this.props.ecp_location === ecpLocationID()}
                                onChange={this.props.onChange}
                              />
                            </Grid.Column>

                            <Grid.Column>
                              <Form.Radio
                                label={t('filter.allLocations')}
                                name='ecp_location'
                                value='all_locations'
                                checked={this.props.ecp_location === 'all_locations'}
                                onChange={this.props.onChange}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Grid.Column>
                  ) : (
                      <Grid.Column>
                        <Divider clearing horizontal section>{t('filter.userHeader')}</Divider>

                        <Grid columns={4} stackable padded doubling>
                          <Grid.Row>
                            <Grid.Column>
                              <Form.Checkbox name='on_site_patient' label={t('filter.onsite')} checked={this.props.on_site_patient} onChange={this.props.onChange} />
                            </Grid.Column>

                            <Grid.Column>
                              <Form.Checkbox name='online_patient' label={t('filter.online')} checked={this.props.online_patient} onChange={this.props.onChange} />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Grid.Column>
                    )
                  }
                </Grid.Row>
              </Grid>
            </Form>
          </Segment>
        </Accordion.Content>
      </Accordion>
    );
  }
}

const TestForm = translate("TestForm")(TestFormComponent);

export default TestForm;
