import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { trim } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import I18n from '../helpers/I18n';

import { setToken, setCurrentUser } from '../actions/authentication';
import { apiJSON } from '../actions/api';

class LoginContainer extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    const token = trim(queryString.parse(this.props.location.search).token);

    setToken(token);

    apiJSON('/api/v2/portal/me').then((user) => {
      setCurrentUser(user);
      I18n.initialize(user);
      this.props.history.push('/tests');
    });
  }

  render() {
    return null;
  }
}

export default withRouter(LoginContainer);
