import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import Layout from '../components/Layout';
import { isProctor } from '../actions/authentication';
import DefaultContainer from './Default';
import CreateSessionForm from '../components/sessions_new/CreateSessionForm';
import { apiJSON } from '../actions/api';
import { postAuthentication } from '../services';

const createSessionEndpoint = '/api/v2/partners/sessions';
const contactsModelsEndpoint = '/api/v2/portal/contacts_models';

class CreateSessionContainer extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.createSession = this.createSession.bind(this);
  }

  async createSession(payload) {
    const user_key = payload.user_key;
    const session_key = payload.session_key;

    await apiJSON(createSessionEndpoint, { method: 'POST', body: JSON.stringify(payload) })

    this.postAuthenticationLink({ user_key, session_key });
  }

  async postAuthenticationLink({ user_key, session_key }) {
    const params = {
      user_key: user_key,
      session_key: session_key,
    };
    const { t } = this.props;

    try {
      const postAuth = await postAuthentication(params);
      window.location.assign(postAuth.link);
    } catch(e) {
      console.error(e);
      alert(t('error'));
    }
  }

  fetchContactsModels() {
    return new Promise((resolve, reject) => {
      apiJSON(contactsModelsEndpoint, { method: 'GET' })
      .then((res) => {
        resolve(res);
      })
      .catch((apiError) => {
        apiError.then((err) => reject(err));
      });
    });
  }

  fetchContactModelDetail(id) {
    return new Promise((resolve, reject) => {
      apiJSON(`/api/v2/portal/contacts_models/${id}`, { method: 'GET' })
      .then((res) => {
        resolve(res);
      })
      .catch((apiError) => {
        apiError.then((err) => reject(err));
      });
    });
  }

  render() {
    if(isProctor()) {
      return (
        <Layout location={this.props.location}>
          <CreateSessionForm
            createSession={this.createSession}
            fetchContactsModels={this.fetchContactsModels}
            fetchContactModelDetail={this.fetchContactModelDetail}
        />
        </Layout>);
    }

    return <DefaultContainer />;
  }
}

export default translate('CreateSession')(withRouter(CreateSessionContainer));
