import React from 'react';
import { Menu, Icon } from 'semantic-ui-react';
import { translate } from 'react-i18next';

function PaginationComponent (props) {
  const { t } = props;
  return (
    <Menu pagination>
      <Menu.Item as='a' onClick={props.handlePrevPage}>
        <Icon fitted name='chevron left' />
      </Menu.Item>

      <Menu.Item>
        {t('pageDesc', {current: props.currentPage || `1`})}
      </Menu.Item>

      <Menu.Item as='a' onClick={props.handleNextPage}>
        <Icon fitted name='chevron right' />
      </Menu.Item>
    </Menu>
  );
}

const Pagination = translate('Pagination')(PaginationComponent);

export default Pagination;
