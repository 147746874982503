import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Segment, Form, Grid, Item, Button, TextArea } from 'semantic-ui-react';
import { isEmpty, findIndex, merge, capitalize, pick, uniqBy, difference } from 'lodash';
import { translate } from 'react-i18next';

import { apiJSON } from '../../actions/api';
import { date, parseErrors, errorsArray, prescriptionEyeShorthand, prescriptionVerification } from '../../helpers/common'
import ContactModelService from '../../services/ContactModelService'
import GlassesRxDetails from '../sessions_new/GlassesRxDetails';
import ContactsRxDetails from '../sessions_new/ContactsRxDetails';
import PriorRxFormErrorMessage from './PriorRxFormErrorMessage';

const CREATE_PRIOR_RX_ENDPOINT = '/api/v2/portal/base_prescriptions';
const INITIAL_STATE = {
  glasses_rx: {
    type: 'glasses',
    verification: null,
    left_eye: {},
    right_eye: {}
  },
  showGlassesRxForm: false,
  validations: {
    verificationIsInvalid: null
  },
  glassesRxFormErrors: {
    left_eye: {},
    right_eye: {}
  },
  contacts_rx: {
    type: 'contacts',
    verification: 'verified',
    left_eye: {},
    right_eye: {}
  },
  contactModelDetails: {
    left_eye: {},
    right_eye: {}
  },
  contactsRxFormErrors: {
    left_eye: {},
    right_eye: {}
  },
  showContactsRxForm: false,
  contactsModels: [],
  comment: '',
  error: undefined,
  errorList: undefined,
  isSubmitting: false,
  submittedRxs: []
};

export class PriorPrescriptionsFormComponent extends Component {
  state = INITIAL_STATE;

  constructor(props) {
    super(props);

    this.fetchContactsModels = this.fetchContactsModels.bind(this);
    this.fetchContactModelDetail = this.fetchContactModelDetail.bind(this);
    this.addRx = this.addRx.bind(this);
    this.removeRx = this.removeRx.bind(this);
    this.handleTextAreaChange = this.handleTextAreaChange.bind(this);
    this.handleRxValue = this.handleRxValue.bind(this);
    this.handleVerificationValue = this.handleVerificationValue.bind(this);
    this.handleCopyEyeValues = this.handleCopyEyeValues.bind(this);
    this.handleErrors = this.handleErrors.bind(this);
    this.hasRequisiteAstigValues = this.hasRequisiteAstigValues.bind(this);
    this.canBeSubmitted = this.canBeSubmitted.bind(this);
    this.submitButtonDisabled = this.submitButtonDisabled.bind(this);
    this.submit = this.submit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.validateFormAndAttemptToSubmit = this.validateFormAndAttemptToSubmit.bind(this);
  }

  componentDidMount() {
    this.fetchContactsModels();
  }

  static propTypes = {
    test: PropTypes.object.isRequired
  }

  fetchContactsModels() {
    ContactModelService.getContactsModels()
      .then((res) => this.setState({
        contactsModels: res.data
      }))
  }

  async fetchContactModelDetail(id) {
    const res = await ContactModelService.getContactModel(id);

    return pick(res.data, ['id', 'avail_sph', 'avail_cyl', 'avail_axis', 'avail_add', 'avail_bc', 'avail_diam']);
  }

  addRx(rx) {
    this.setState(
      rx === 'contacts_rx' ?
        { showContactsRxForm: true } :
        { showGlassesRxForm: true }
    );
  }

  removeRx(rx) {
    this.setState(
      rx === 'contacts_rx' ? (
          {
            showContactsRxForm: false,
            contacts_rx: {
              type: 'contacts',
              verification: 'verified',
              left_eye: {},
              right_eye: {},
            }
          }
        ) : (
          {
            showGlassesRxForm: false,
            glasses_rx: {
              type: 'glasses',
              verification: null,
              left_eye: {},
              right_eye: {}
            },
            validations: {
              verificationIsInvalid: false
            }
          }
        )
    );
  }

  handleTextAreaChange(value) {
    this.setState({
      comment: value
    });
  }

  async handleRxValue(whichRx, eye, rxField, rxValue) {
    if (rxField === 'product_key' && whichRx === 'contacts_rx') {
      await this.handleContactModelChange(eye, rxValue);
    }

    const rx = Object.assign({}, this.state[whichRx]);
    rx[eye][rxField] = rxValue;
    return new Promise((res) => {
      this.setState({ [whichRx]: rx }, res);
    });
  }

  async handleContactModelChange(eye, contactModelId) {
    try {
      const { id, avail_sph, avail_cyl, avail_axis, avail_add, avail_bc, avail_diam } = await this.fetchContactModelDetail(contactModelId);
      const eyeContactDetail = Object.assign(
        {},
        this.state.contactModelDetails[eye],
        { avail_sph, avail_cyl, avail_axis, avail_add, avail_bc, avail_diam },
      );
      const rx = this.handleSingluarContactModelDetailValues(eye, id, eyeContactDetail);

      this.setState({
        contacts_rx: {
          ...this.state.contacts_rx,
          [eye]: rx
        },
        contactModelDetails: {
          ...this.state.contactModelDetails,
          [eye]: eyeContactDetail
        }
      });
    } catch (e) {
      console.error(e);
      alert('Contacts Model cannot be found!');
    }
  }

  handleSingluarContactModelDetailValues(eye, id, eyeContactDetail) {
    const rx = { product_key: id };
    const allKeys = {
      'sphere': 'avail_sph',
      'cylinder': 'avail_cyl',
      'axis': 'avail_axis',
      'add': 'avail_add',
      'base_curve': 'avail_bc',
      'diameter': 'avail_diam'
    };

    Object.entries(allKeys).forEach(([rxField, detailField]) => {
      if (eyeContactDetail[detailField] && eyeContactDetail[detailField].length === 1) {
        rx[rxField] = eyeContactDetail[detailField][0];
      };
    })

    return rx;
  }

  handleVerificationValue(value) {
    this.setState({
      glasses_rx: {...this.state.glasses_rx, verification: value },
      validations: {verificationIsInvalid: false}
    });
  }

  handleCopyEyeValues(whichRx, eye) {
    const eyeToReceiveCopyValues = eye === 'right_eye' ? 'left_eye' : 'right_eye';
    const rxCopyValues = Object.assign({}, this.state[whichRx][eye]);
    const newStateValues = {
      [whichRx]: {
        ...this.state[whichRx],
        [eyeToReceiveCopyValues]: rxCopyValues,
      },
    };

    if (whichRx === 'contacts_rx') {
      const eyeContactDetailCopyValues = Object.assign({}, this.state.contactModelDetails[eye]);

      Object.assign(newStateValues, {
        contactModelDetails: {
          ...this.state.contactModelDetails,
          [eyeToReceiveCopyValues]: eyeContactDetailCopyValues,
        }
      });
    };

    this.setState(newStateValues);
  }

  handleErrors(error, payload) {
    let allGlassesErrors = {
      left_eye: {},
      right_eye: {}
    };
    let allContactsErrors = {
      left_eye: {},
      right_eye: {}
    };

    const errorList = [];
    const glassesRX = findIndex(payload.prescriptions, rx => rx.type === 'glasses');
    const contactsRX = findIndex(payload.prescriptions, rx => rx.type === 'contacts');
    const errors = parseErrors(error.errors);

    if (errors.prescriptions && errors.prescriptions[glassesRX]) {
      merge(allGlassesErrors, errors.prescriptions[glassesRX]);
      errorList.push(errorsArray('glasses', allGlassesErrors));
    }

    if (errors.prescriptions && errors.prescriptions[contactsRX]) {
      merge(allContactsErrors, errors.prescriptions[contactsRX]);
      errorList.push(errorsArray('contacts', allContactsErrors));
    }

    this.setState({
      isSubmitting: false,
      error: error,
      errorList: errorList,
      glassesRxFormErrors: allGlassesErrors,
      contactsRxFormErrors: allContactsErrors
    });
  }

  hasRequisiteAstigValues (eye) {
    const requiresAstigValues = this.state.contactModelDetails[eye].avail_cyl && this.state.contactModelDetails[eye].avail_cyl.length;
    return !requiresAstigValues || this.state.contacts_rx[eye].cylinder;
  }

  canBeSubmitted() {
    const { glasses_rx, contacts_rx } = this.state;
    const glassesRxCanBeSubmitted = ![glasses_rx.right_eye, glasses_rx.left_eye].some(isEmpty);
    const contactsRxCanBeSubmitted = ![contacts_rx.right_eye, contacts_rx.left_eye].some(isEmpty);

    return glassesRxCanBeSubmitted || (contactsRxCanBeSubmitted && ['right_eye', 'left_eye'].every(this.hasRequisiteAstigValues, this));
  }

  validateForm () {
    const { glasses_rx, showGlassesRxForm } = this.state;
    const { verification } = glasses_rx;
    const verificationIsInvalid = showGlassesRxForm && !verification;
    this.setState({ validations: { verificationIsInvalid } });
    const formIsInvalid = verificationIsInvalid;
    return !formIsInvalid;
  }

  validateFormAndAttemptToSubmit () {
    if (this.state.isSubmitting) return;

    this.setState({
      error: undefined,
      errorList: undefined,
    });

    return this.validateForm() ?
      this.submit() :
      null;
  }

  async submit () {
    this.setState({isSubmitting: true})
    const {
      showGlassesRxForm,
      glasses_rx,
      showContactsRxForm,
      contacts_rx,
      comment
    } = this.state;

    const prescriptions = []
    if(showGlassesRxForm) { prescriptions.push(glasses_rx); }
    if(showContactsRxForm) { prescriptions.push(contacts_rx); }

    const payload = {
      exam_id: this.props.test.id,
      prescriptions,
      comment: comment
    };

    try {
      const rxs = await apiJSON(
        CREATE_PRIOR_RX_ENDPOINT,
        {
          method: 'POST',
          body: JSON.stringify(payload)
        }
      );

      const postSubmitState = Object.assign(
        INITIAL_STATE, {
          contactsModels: this.state.contactsModels,
          submittedRxs: [...this.state.submittedRxs, ...rxs]
        }
      );

      this.setState(
        postSubmitState
      );
    } catch(e) {
      console.error(e);

      this.handleErrors(e, payload);

      this.setState({
        isSubmitting: false,
        error: e
      });

      window.scrollTo(0, 0);
    }
  }

  submitButtonDisabled () {
    return !this.canBeSubmitted() || this.state.isSubmitting;
  }

  render() {
    const { test, t } = this.props;
    const showPriorRxsForm = test.status === 'missing_requirement';
    const confirmedBaseRxs = uniqBy(test.base_prescriptions.concat(this.state.submittedRxs), 'id');
    const hasNoConfirmedGlassesRxs = !confirmedBaseRxs.some(rx => rx.type === 'glasses');
    const hasNoConfirmedContactsRxs = !confirmedBaseRxs.some(rx => rx.type === 'contacts');
    const hasSubmittableGlassesRx = test.submittable_rx_types.includes('glasses');
    const hasSubmittableContactsRx = test.submittable_rx_types.includes('contacts');
    const showGlassesPriorRxDetail = hasNoConfirmedGlassesRxs && hasSubmittableGlassesRx;
    const showContactsPriorRxDetail = hasNoConfirmedContactsRxs && hasSubmittableContactsRx;
    const rxTypesMissing = test.exam_requirements.base_prescriptions.missing;
    const submittedRxTypes = this.state.submittedRxs.map((rx) => capitalize(rx.type));
    const baseRxTypesMissing = difference(rxTypesMissing, submittedRxTypes).map((rx) => t(rx));
    // revisit
    const missingTypes = (baseRxTypesMissing !== undefined || baseRxTypesMissing.length > 0) && baseRxTypesMissing.join(', ');

    return (
      <div>
        {
          missingTypes ? (
            <Segment className={test.status === "referred" ? '' : `Patient__VisionTest__ManuallySubmittedBasePrescriptions__Status--missing`}>
              <p>{ t('missingBanner', {types: missingTypes}) }</p>
            </Segment>
          )
          :
          (
            <Segment className={`Patient__VisionTest__ManuallySubmittedBasePrescriptions__Status--${test.exam_requirements.base_prescriptions}`}>
            {
              rxTypesMissing ?
                <p>{ t('submitted') }</p>
                :
                <p>{t(test.exam_requirements.base_prescriptions)}</p>
            }
            </Segment>
          )
        }
        {
          !isEmpty(confirmedBaseRxs) &&
            <Segment>
              <Item.Group>
                {
                  confirmedBaseRxs.map((prescription) =>
                    <Item className='Patient__VisionTest__ManuallySubmittedBasePrescription' key={prescription.id}>
                      <Item.Content>
                        <p>{t(prescription.type)}</p>
                        <p>
                          {
                            prescription.patient_rating === 5 ?
                              t('happyWithVision')
                              :
                              t('unhappyWithVision')
                          }
                          {
                            prescription.type === 'contacts' && this.props.test.comfortable_in_contacts ?
                              t('comfortable')
                              :
                              null
                          }
                          {
                            prescriptionVerification(prescription) ?
                              t('verified', { method: prescriptionVerification(prescription) })
                              :
                              null
                          }
                        </p>
                        {
                          prescription.issue_date || prescription.expiration_date ?
                            t('rxTimes', {
                              issue: date(prescription.issue_date) || <i>{t('unavailable')}</i>,
                              expiration: date(prescription.expiration_date) || <i>{t('unavailable')}</i>}
                            )
                            : 
                            null
                        }
                        <p><code>OD:</code> {prescriptionEyeShorthand(prescription.right_eye)}</p>
                        <p><code>OS:</code> {prescriptionEyeShorthand(prescription.left_eye)}</p>
                        <br></br>
                      </Item.Content>
                    </Item>
                  )
                }
              </Item.Group>
            </Segment>
        }
        {
          showPriorRxsForm && (showGlassesPriorRxDetail || showContactsPriorRxDetail) &&
            <Container>
              <PriorRxFormErrorMessage
                error={this.state.error}
                errorList={this.state.errorList} />
              <Form id='create_prior_rx_form' onSubmit={()=>this.validateFormAndAttemptToSubmit()}>
                <Segment>
                  <Grid stackable>
                    <Grid.Column>
                    {
                      showGlassesPriorRxDetail &&
                        <Item.Group>
                          <Item>
                            <Item.Content>
                              <Item.Header>
                                { t('priorGlassesHeader') }
                              </Item.Header>
                              <GlassesRxDetails
                                regionRefractionActive={test.region_refraction_active}
                                examType={test.type}
                                showGlassesRxForm={this.state.showGlassesRxForm}
                                addRx={this.addRx}
                                removeRx={this.removeRx}
                                handleRxValue={this.handleRxValue}
                                glassesRxValues={this.state.glasses_rx}
                                showErrorPopup={this.state.validations.verificationIsInvalid}
                                verificationValue={this.state.glasses_rx.verification}
                                handleVerificationValue={this.handleVerificationValue}
                                glassesRxFormErrors={this.state.glassesRxFormErrors}
                                handleCopyEyeValues={this.handleCopyEyeValues} />
                            </Item.Content>
                          </Item>
                        </Item.Group>
                    }
                    {
                      showContactsPriorRxDetail &&
                        <Item.Group>
                          <Item>
                            <Item.Content>
                              <Item.Header>
                                { t('priorContactsHeader') }
                              </Item.Header>
                              <ContactsRxDetails
                                showContactsRxForm={this.state.showContactsRxForm}
                                addRx={this.addRx}
                                removeRx={this.removeRx}
                                handleRxValue={this.handleRxValue}
                                contactsRxValues={this.state.contacts_rx}
                                contactsModels={this.state.contactsModels}
                                contactModelDetails={this.state.contactModelDetails}
                                contactsRxFormErrors={this.state.contactsRxFormErrors}
                                handleCopyEyeValues={this.handleCopyEyeValues} />
                            </Item.Content>
                          </Item>
                        </Item.Group>
                    }
                    <TextArea
                      placeholder={t('comments')}
                      value={this.state.comment}
                      onChange={(e, { value }) => this.handleTextAreaChange(value)}
                    />
                    </Grid.Column>
                  </Grid>
                </Segment>

                <Grid stackable centered>
                  <Grid.Column width='8'>
                    <Button
                      type='submit'
                      className='branded__button branded__button--primary'
                      fluid
                      disabled={this.submitButtonDisabled()}>
                      {t('submit')}
                    </Button>
                  </Grid.Column>
                </Grid>
              </Form>
            </Container>
        }
      </div>
    )
  }
}

const PriorPrescriptionsForm = translate('PriorPrescriptionsForm')(PriorPrescriptionsFormComponent);

export default PriorPrescriptionsForm;
