import React from 'react';
import { Message } from 'semantic-ui-react';
import { flattenDeep } from 'lodash';
import { translate } from 'react-i18next';

function SessionFormErrorMessageComponent({ t, error, errorList }) {
  if(!!error) {
    if(errorList) {
      return (
        <Message color='red'>
          <Message.Header>{t('header')}</Message.Header>
          <Message.List items={flattenDeep(errorList)} />
        </Message>
      );
    }

    if(!!error.disqualifications) {
      return (
        <Message color='red'>
          <Message.Header>{t('header')}</Message.Header>
          {t('dqSubheader')}
          <Message.List items={error.disqualifications} />
        </Message>
      );
    }
  }

  return null;
}

const SessionFormErrorMessage = translate('SessionFormErrorMessage')(SessionFormErrorMessageComponent);

export default SessionFormErrorMessage;