import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { translate } from 'react-i18next';
import Layout from '../components/Layout';
import ExplorePatientsContainer from './ExplorePatientsContainer';
import { apiJSON } from '../actions/api';

class PatientIndexContainer extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  async fetchPatients(patientFormAttributes) {
    const { t } = this.props;
    const query = queryString.stringify(patientFormAttributes);

    try {
      const patients = await apiJSON(`/api/v2/portal/patients?${query}`);
      return patients;
    } catch(e) {
      console.log(e);
      alert(t('error'));
    }
  };

  render() {
    const { t } = this.props;
    return (
      <Layout location={this.props.location}>
        <h1>{t('header')}</h1>
        <ExplorePatientsContainer fetchPatients={(patientFormAttributes) => this.fetchPatients(patientFormAttributes)} />
      </Layout>
    )
  }
}

export default translate('PatientIndex')(withRouter(PatientIndexContainer));
